import React, { useState, Fragment } from 'react';
import { Listbox, Transition } from "@headlessui/react";
import Fermentable from '../models/Fermentable';

//https://github.com/tailwindlabs/headlessui/blob/develop/packages/%40headlessui-react/README.md
// https://tailwindui.com/components/application-ui/forms/select-menus#component-71d9116be789a254c260369f03472985
// https://tailwindui.com/documentation#integrating-with-javascript-frameworks

export default function FermentableSelector(props) {
  const initialFermentable = 47; // american pilsner
  const [selectedFermentable, setSelectedFermentable] = useState(Fermentable.find(initialFermentable));

  const buildFermentableName = fermentable => {
    let name = fermentable.name;

    if (fermentable.country.length > 0) {
      name += ` - ${fermentable.country}`;
    }

    if (fermentable.brand.length > 0) {
      name += ` (${fermentable.brand})`;
    }

    if (fermentable.lovibond > 0) {
      name += ` ${fermentable.lovibond}° L`;
    }

    return name;
  }

  const fermentableChanged = (event) => {
    setSelectedFermentable(event);
    props.onChange(event);
  }

  return (
    <Listbox
      as="div"
      className="mt-1 relative"
      value={selectedFermentable}
      onChange={fermentableChanged}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <span className="inline-block w-full rounded-md shadow-sm">
              <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                <span className="block truncate">{buildFermentableName(selectedFermentable)}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Listbox.Button>
            </span>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50"
            >
              <Listbox.Options
                static
                className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              >
                {Fermentable.all().map((fermentable) => (
                  <Listbox.Option key={fermentable.id} value={fermentable}>
                    {({ selected, active }) => (
                      <div
                        className={`${
                              active
                                ? "text-white bg-blue-600"
                                : "text-gray-900"
                            } cursor-default select-none relative py-2 pl-8 pr-4`}
                      >
                        <span
                          className={`${
                                selected ? "font-semibold" : "font-normal"
                              } block truncate`}
                        >
                          {buildFermentableName(fermentable)}
                        </span>
                        {selected && (
                          <span
                            className={`${
                                  active ? "text-white" : "text-blue-600"
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                          >
                            <svg
                              className="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
