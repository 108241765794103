const UNSORTED_FERMENTABLES = [
  {
    "id":1920,
    "brand":"Coopers",
    "name":"Pale Ale kit",
    "country":"Australian",
    "lovibond":4,
    "ppg":35,
    "mashable":0,
    "category":"",
    "diastatic":110,
    "graintype":"base malt"
  },
  {"id":17,"brand":"","name":"Aromatic Malt","country":"","lovibond":20,"ppg":35,"mashable":1,"category":"Grain","diastatic":20,"graintype":"specialty malt"},
  {"id":18,"brand":"","name":"Ashburne Mild","country":"","lovibond":5.3,"ppg":36,"mashable":1,"category":"Grain","diastatic":65,"graintype":"base malt"},
  {"id":1425,"brand":"","name":"Extra Pale Malt","country":"","lovibond":1.8,"ppg":33,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":74,"brand":"","name":"Honey Malt","country":"","lovibond":25,"ppg":37,"mashable":1,"category":"Grain","diastatic":50,"graintype":"crystal malt"},
  {"id":318,"brand":"","name":"Manuka Smoked Malt","country":"","lovibond":2.03046,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":120,"graintype":"smoked malt"},
  {"id":148,"brand":"","name":"Munich","country":"","lovibond":6,"ppg":37,"mashable":1,"category":"Grain","diastatic":40,"graintype":"specialty malt"},
  {"id":42,"brand":"","name":"Munich - 60L","country":"","lovibond":60,"ppg":33,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":40,"brand":"","name":"Munich - Light 10L","country":"","lovibond":10,"ppg":33,"mashable":1,"category":"Grain","diastatic":40,"graintype":"specialty malt"},
  {"id":75,"brand":"","name":"Munich Dark 20L","country":"","lovibond":20,"ppg":34,"mashable":1,"category":"Grain","diastatic":30,"graintype":"specialty malt"},
  {"id":320,"brand":"","name":"Munich Malt","country":"","lovibond":7.86802,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":74,"graintype":"specialty malt"},
  {"id":43,"brand":"","name":"Pale 2-Row","country":"","lovibond":1.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":154,"brand":"","name":"Peated Malt","country":"","lovibond":2.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":239,"brand":"","name":"Red X","country":"","lovibond":12,"ppg":36,"mashable":1,"category":"Grain","diastatic":62,"graintype":"specialty malt"},
  {"id":51,"brand":"","name":"Smoked Malt","country":"","lovibond":5,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"smoked malt"},
  {"id":116,"brand":"","name":"Smoked Malt","country":"","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":50,"graintype":"smoked malt"},
  {"id":331,"brand":"","name":"Toffee Malt","country":"","lovibond":5.32995,"ppg":38.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":19,"brand":"","name":"Black Barley","country":"American","lovibond":530,"ppg":27,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":20,"brand":"","name":"Black Malt","country":"American","lovibond":500,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":21,"brand":"","name":"Blackprinz","country":"American","lovibond":500,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":193,"brand":"","name":"Buckwheat Malt - Gluten Free","country":"American","lovibond":2,"ppg":25,"mashable":1,"category":"Grain","diastatic":120,"graintype":"gluten-free malt"},
  {"id":23,"brand":"","name":"CaraBrown","country":"American","lovibond":55,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":24,"brand":"","name":"CaraCrystal Wheat Malt","country":"American","lovibond":55,"ppg":34,"mashable":1,"category":"Grain","diastatic":120,"graintype":"crystal malt"},
  {"id":25,"brand":"","name":"Caramel / Crystal 10L","country":"American","lovibond":10,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":34,"brand":"","name":"Caramel / Crystal 120L","country":"American","lovibond":120,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":35,"brand":"","name":"Caramel / Crystal 150L","country":"American","lovibond":150,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":26,"brand":"","name":"Caramel / Crystal 15L","country":"American","lovibond":15,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":27,"brand":"","name":"Caramel / Crystal 20L","country":"American","lovibond":20,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":28,"brand":"","name":"Caramel / Crystal 30L","country":"American","lovibond":30,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":29,"brand":"","name":"Caramel / Crystal 40L","country":"American","lovibond":40,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":30,"brand":"","name":"Caramel / Crystal 60L","country":"American","lovibond":60,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":31,"brand":"","name":"Caramel / Crystal 75L","country":"American","lovibond":75,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":32,"brand":"","name":"Caramel / Crystal 80L","country":"American","lovibond":80,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":33,"brand":"","name":"Caramel / Crystal 90L","country":"American","lovibond":90,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":36,"brand":"","name":"Carapils (Dextrine Malt)","country":"American","lovibond":1.8,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":37,"brand":"","name":"Chocolate","country":"American","lovibond":350,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":198,"brand":"","name":"Crystal 10L Millet Malt - Gluten Free","country":"American","lovibond":10,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":199,"brand":"","name":"Crystal 30L Millet Malt - Gluten Free","country":"American","lovibond":30,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":38,"brand":"","name":"Dark Chocolate","country":"American","lovibond":420,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":197,"brand":"","name":"Dark Roasted Millet Malt - Gluten Free","country":"American","lovibond":300,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":195,"brand":"","name":"Light Roasted Millet Malt - Gluten Free","country":"American","lovibond":7,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":196,"brand":"","name":"Medium Roasted Millet Malt - Gluten Free","country":"American","lovibond":36,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":39,"brand":"","name":"Midnight Wheat Malt","country":"American","lovibond":550,"ppg":33,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":194,"brand":"","name":"Munich Millet Malt - Gluten Free","country":"American","lovibond":7,"ppg":25,"mashable":1,"category":"Grain","diastatic":70,"graintype":"gluten-free malt"},
  {"id":44,"brand":"","name":"Pale 2-Row - Toasted","country":"American","lovibond":30,"ppg":33,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":45,"brand":"","name":"Pale 6-Row","country":"American","lovibond":1.8,"ppg":35,"mashable":1,"category":"Grain","diastatic":160,"graintype":"base malt"},
  {"id":46,"brand":"","name":"Pale Ale","country":"American","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":192,"brand":"","name":"Pale Millet Malt - Gluten Free","country":"American","lovibond":1.5,"ppg":30,"mashable":1,"category":"Grain","diastatic":110,"graintype":"gluten-free malt"},
  {"id":47,"brand":"","name":"Pilsner","country":"American","lovibond":1.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":48,"brand":"","name":"Red Wheat","country":"American","lovibond":2.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":180,"graintype":"base malt"},
  {"id":49,"brand":"","name":"Roasted Barley","country":"American","lovibond":300,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":50,"brand":"","name":"Rye","country":"American","lovibond":3.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":105,"graintype":"base malt"},
  {"id":52,"brand":"","name":"Special Roast","country":"American","lovibond":50,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":53,"brand":"","name":"Victory","country":"American","lovibond":28,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":54,"brand":"","name":"Vienna","country":"American","lovibond":4,"ppg":35,"mashable":1,"category":"Grain","diastatic":130,"graintype":"base malt"},
  {"id":55,"brand":"","name":"Wheat","country":"American","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":56,"brand":"","name":"White Wheat","country":"American","lovibond":2.8,"ppg":40,"mashable":1,"category":"Grain","diastatic":160,"graintype":"base malt"},
  {"id":57,"brand":"","name":"Aromatic","country":"Belgian","lovibond":38,"ppg":33,"mashable":1,"category":"Grain","diastatic":30,"graintype":"roasted malt"},
  {"id":58,"brand":"","name":"Biscuit","country":"Belgian","lovibond":23,"ppg":35,"mashable":1,"category":"Grain","diastatic":6,"graintype":"roasted malt"},
  {"id":59,"brand":"","name":"Cara 20L","country":"Belgian","lovibond":22,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":60,"brand":"","name":"Cara 45L","country":"Belgian","lovibond":42,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":61,"brand":"","name":"Caramel Pils","country":"Belgian","lovibond":8,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":62,"brand":"","name":"CaraMunich","country":"Belgian","lovibond":50,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":63,"brand":"","name":"CaraVienne","country":"Belgian","lovibond":20,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":64,"brand":"","name":"Chocolate","country":"Belgian","lovibond":340,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":65,"brand":"","name":"De-Bittered Black","country":"Belgian","lovibond":566,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":67,"brand":"","name":"Pale Ale","country":"Belgian","lovibond":3.4,"ppg":38,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":68,"brand":"","name":"Pilsner","country":"Belgian","lovibond":1.6,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":69,"brand":"","name":"Roasted Barley","country":"Belgian","lovibond":575,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":70,"brand":"","name":"Special B","country":"Belgian","lovibond":115,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":72,"brand":"","name":"Wheat","country":"Belgian","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":74,"graintype":"base malt"},
  {"id":73,"brand":"","name":"ESB Malt","country":"Canadian","lovibond":3.5,"ppg":36,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":77,"brand":"","name":"Pale 2-Row","country":"Canadian","lovibond":1.75,"ppg":36,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":78,"brand":"","name":"Pale Wheat","country":"Canadian","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":218,"brand":"","name":"Black Malt","country":"Finland","lovibond":526,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":208,"brand":"","name":"Cara Pale","country":"Finland","lovibond":4,"ppg":35,"mashable":1,"category":"Grain","diastatic":110,"graintype":"crystal malt"},
  {"id":214,"brand":"","name":"Cara Plus 100","country":"Finland","lovibond":38,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":215,"brand":"","name":"Cara Plus 150","country":"Finland","lovibond":57,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":216,"brand":"","name":"Cara Plus 200","country":"Finland","lovibond":76,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":217,"brand":"","name":"Cara Plus 250","country":"Finland","lovibond":94,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":219,"brand":"","name":"Chocolate Malt","country":"Finland","lovibond":338,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":220,"brand":"","name":"Cookie Malt","country":"Finland","lovibond":19,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":210,"brand":"","name":"Crystal Malt 100","country":"Finland","lovibond":38,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":211,"brand":"","name":"Crystal Malt 150","country":"Finland","lovibond":57,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":212,"brand":"","name":"Crystal Malt 250","country":"Finland","lovibond":94,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":213,"brand":"","name":"Crystal Malt 300","country":"Finland","lovibond":113,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":209,"brand":"","name":"Crystal Malt 50","country":"Finland","lovibond":19,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":205,"brand":"","name":"Dark Ale","country":"Finland","lovibond":14,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":222,"brand":"","name":"Enzyme Malt","country":"Finland","lovibond":0,"ppg":35,"mashable":1,"category":"Grain","diastatic":180,"graintype":"base malt"},
  {"id":204,"brand":"","name":"Melanoid Malt","country":"Finland","lovibond":27,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":202,"brand":"","name":"Organic Pilsner Malt","country":"Finland","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":203,"brand":"","name":"Pale Ale Malt","country":"Finland","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":201,"brand":"","name":"Pilsner Malt","country":"Finland","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":207,"brand":"","name":"Vienna Malt","country":"Finland","lovibond":3,"ppg":36,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":221,"brand":"","name":"Wheat Malt","country":"Finland","lovibond":2,"ppg":38,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":79,"brand":"","name":"Abbey Malt","country":"German","lovibond":17,"ppg":33,"mashable":1,"category":"Grain","diastatic":30,"graintype":"base malt"},
  {"id":80,"brand":"","name":"Acidulated Malt","country":"German","lovibond":3.4,"ppg":27,"mashable":1,"category":"Grain","diastatic":10,"graintype":"acidulated malt"},
  {"id":81,"brand":"","name":"Bohemian Pilsner","country":"German","lovibond":1.9,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":82,"brand":"","name":"CaraAmber","country":"German","lovibond":23,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":83,"brand":"","name":"CaraAroma","country":"German","lovibond":130,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":85,"brand":"","name":"CaraBohemian","country":"German","lovibond":75,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":86,"brand":"","name":"Carafa I","country":"German","lovibond":340,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":87,"brand":"","name":"Carafa II","country":"German","lovibond":425,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":88,"brand":"","name":"Carafa III","country":"German","lovibond":535,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":89,"brand":"","name":"CaraFoam","country":"German","lovibond":1.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":90,"brand":"","name":"CaraHell","country":"German","lovibond":11,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":91,"brand":"","name":"Caramel Pils","country":"German","lovibond":2.4,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":92,"brand":"","name":"Caramel Wheat","country":"German","lovibond":46,"ppg":34,"mashable":1,"category":"Grain","diastatic":120,"graintype":"crystal malt"},
  {"id":93,"brand":"","name":"CaraMunich I","country":"German","lovibond":39,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":94,"brand":"","name":"CaraMunich II","country":"German","lovibond":46,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":95,"brand":"","name":"CaraMunich III","country":"German","lovibond":57,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":96,"brand":"","name":"Carapils","country":"German","lovibond":1.3,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":97,"brand":"","name":"CaraRed","country":"German","lovibond":20,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":339,"brand":"","name":"CaraRye","country":"German","lovibond":67,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":98,"brand":"","name":"Chocolate Rye","country":"German","lovibond":240,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":99,"brand":"","name":"Chocolate Wheat","country":"German","lovibond":413,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":101,"brand":"","name":"Dark Wheat","country":"German","lovibond":6.5,"ppg":39,"mashable":1,"category":"Grain","diastatic":10,"graintype":"base malt"},
  {"id":102,"brand":"","name":"De-Husked Caraf I","country":"German","lovibond":340,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":103,"brand":"","name":"De-Husked Caraf II","country":"German","lovibond":418,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":104,"brand":"","name":"De-Husked Caraf III","country":"German","lovibond":470,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":105,"brand":"","name":"Floor-Malted Bohemian Pilsner","country":"German","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":106,"brand":"","name":"Floor-Malted Bohemian Pilsner Dk","country":"German","lovibond":6.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":107,"brand":"","name":"Floor-Malted Bohemian Wheat","country":"German","lovibond":2,"ppg":38,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":108,"brand":"","name":"K\u00f6lsch","country":"German","lovibond":4.4,"ppg":37,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":109,"brand":"","name":"Melanoidin","country":"German","lovibond":25,"ppg":37,"mashable":1,"category":"Grain","diastatic":10,"graintype":"roasted malt"},
  {"id":112,"brand":"","name":"Pale Ale","country":"German","lovibond":2.3,"ppg":39,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":113,"brand":"","name":"Pale Wheat","country":"German","lovibond":1.5,"ppg":39,"mashable":1,"category":"Grain","diastatic":95,"graintype":"base malt"},
  {"id":114,"brand":"","name":"Pilsner","country":"German","lovibond":1.6,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":115,"brand":"","name":"Rye","country":"German","lovibond":3.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":117,"brand":"","name":"Spelt Malt","country":"German","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":30,"graintype":"base malt"},
  {"id":118,"brand":"","name":"Vienna","country":"German","lovibond":4,"ppg":37,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":119,"brand":"","name":"Wheat Malt","country":"German","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":242,"brand":"","name":"Ale Malt","country":"Ireland","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":245,"brand":"","name":"Distillers Malt","country":"Ireland","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":243,"brand":"","name":"Lager Malt","country":"Ireland","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":244,"brand":"","name":"Stout Malt","country":"Ireland","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":306,"brand":"","name":"Ale Malt","country":"New Zealand","lovibond":3.04569,"ppg":37.4,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":307,"brand":"","name":"American Ale Malt","country":"New Zealand","lovibond":2.53807,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":308,"brand":"","name":"Aurora Malt","country":"New Zealand","lovibond":29.4416,"ppg":37.2,"mashable":1,"category":"Grain","diastatic":57,"graintype":"base malt"},
  {"id":309,"brand":"","name":"Biscuit Malt","country":"New Zealand","lovibond":30.4569,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":310,"brand":"","name":"Brown Malt","country":"New Zealand","lovibond":90.3553,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":311,"brand":"","name":"Dark Chocolate Malt","country":"New Zealand","lovibond":659.898,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":312,"brand":"","name":"Dark Crystal Malt","country":"New Zealand","lovibond":96.4467,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":313,"brand":"","name":"Gladiator Malt","country":"New Zealand","lovibond":5.07614,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":73,"graintype":"base malt"},
  {"id":314,"brand":"","name":"Lager Light","country":"New Zealand","lovibond":1.4264,"ppg":37,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":315,"brand":"","name":"Light Chocolate Malt","country":"New Zealand","lovibond":456.853,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":316,"brand":"","name":"Light Crystal Malt","country":"New Zealand","lovibond":31.9797,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":317,"brand":"","name":"Malted Rye","country":"New Zealand","lovibond":3,"ppg":40.2,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":319,"brand":"","name":"Medium Crystal Malt","country":"New Zealand","lovibond":56.3452,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":321,"brand":"","name":"Organic Pilsner","country":"New Zealand","lovibond":1.58629,"ppg":37.9,"mashable":1,"category":"Grain","diastatic":45,"graintype":"base malt"},
  {"id":322,"brand":"","name":"Pilsner Malt","country":"New Zealand","lovibond":1.92893,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":323,"brand":"","name":"Red Back Malt","country":"New Zealand","lovibond":32.9949,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":324,"brand":"","name":"Roast Barley","country":"New Zealand","lovibond":736.041,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":325,"brand":"","name":"Roasted Wheat","country":"New Zealand","lovibond":279.188,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":328,"brand":"","name":"Shepherds Delight Malt","country":"New Zealand","lovibond":152.284,"ppg":36.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":329,"brand":"","name":"Sour Grapes Malt","country":"New Zealand","lovibond":2.03046,"ppg":34,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":330,"brand":"","name":"Supernova Malt","country":"New Zealand","lovibond":58.3756,"ppg":37.4,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":332,"brand":"","name":"Vienna Malt","country":"New Zealand","lovibond":3.45178,"ppg":39.1,"mashable":1,"category":"Grain","diastatic":71,"graintype":"base malt"},
  {"id":333,"brand":"","name":"Wheat Malt","country":"New Zealand","lovibond":2.13198,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":73,"graintype":"base malt"},
  {"id":120,"brand":"","name":"Amber","country":"United Kingdom","lovibond":27,"ppg":32,"mashable":1,"category":"Grain","diastatic":20,"graintype":"base malt"},
  {"id":121,"brand":"","name":"Black Patent","country":"United Kingdom","lovibond":525,"ppg":27,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":122,"brand":"","name":"Brown","country":"United Kingdom","lovibond":65,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":123,"brand":"","name":"Cara Malt","country":"United Kingdom","lovibond":17.5,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":124,"brand":"","name":"Carastan (30/37)","country":"United Kingdom","lovibond":34,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":125,"brand":"","name":"Carastan Light (15L)","country":"United Kingdom","lovibond":15,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":126,"brand":"","name":"Chocolate","country":"United Kingdom","lovibond":425,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":127,"brand":"","name":"Coffee Malt","country":"United Kingdom","lovibond":150,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":135,"brand":"","name":"Crystal 140L","country":"United Kingdom","lovibond":140,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":128,"brand":"","name":"Crystal 15L","country":"United Kingdom","lovibond":15,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":129,"brand":"","name":"Crystal 30L","country":"United Kingdom","lovibond":30,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":130,"brand":"","name":"Crystal 45L","country":"United Kingdom","lovibond":45,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":131,"brand":"","name":"Crystal 50L","country":"United Kingdom","lovibond":50,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":338,"brand":"","name":"Crystal 55L","country":"United Kingdom","lovibond":55,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":132,"brand":"","name":"Crystal 60L","country":"United Kingdom","lovibond":60,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":133,"brand":"","name":"Crystal 70L","country":"United Kingdom","lovibond":70,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":134,"brand":"","name":"Crystal 90L","country":"United Kingdom","lovibond":90,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":136,"brand":"","name":"Crystal Rye","country":"United Kingdom","lovibond":90,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":138,"brand":"","name":"Dark Crystal 80L","country":"United Kingdom","lovibond":80,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":137,"brand":"","name":"Dextrine Malt","country":"United Kingdom","lovibond":1.8,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":139,"brand":"","name":"Extra Dark Crystal 120L","country":"United Kingdom","lovibond":120,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":140,"brand":"","name":"Extra Dark Crystal 160L","country":"United Kingdom","lovibond":160,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":141,"brand":"","name":"Golden Naked Oats","country":"United Kingdom","lovibond":10,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":142,"brand":"","name":"Golden Promise","country":"United Kingdom","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":143,"brand":"","name":"Halcyon","country":"United Kingdom","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":144,"brand":"","name":"Lager","country":"United Kingdom","lovibond":1.4,"ppg":38,"mashable":1,"category":"Grain","diastatic":67,"graintype":"base malt"},
  {"id":145,"brand":"","name":"Malted Naked Oats","country":"United Kingdom","lovibond":1.3,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":146,"brand":"","name":"Maris Otter Pale","country":"United Kingdom","lovibond":3.75,"ppg":38,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":147,"brand":"","name":"Mild","country":"United Kingdom","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":149,"brand":"","name":"Oat Malt","country":"United Kingdom","lovibond":2,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":150,"brand":"","name":"Optic","country":"United Kingdom","lovibond":2.1,"ppg":38,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":151,"brand":"","name":"Pale 2-Row","country":"United Kingdom","lovibond":2.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":152,"brand":"","name":"Pale Chocolate","country":"United Kingdom","lovibond":207,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":153,"brand":"","name":"Pearl","country":"United Kingdom","lovibond":2.1,"ppg":37,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":155,"brand":"","name":"Pilsen","country":"United Kingdom","lovibond":1.8,"ppg":36,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":156,"brand":"","name":"Roasted Barley","country":"United Kingdom","lovibond":550,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":157,"brand":"","name":"Wheat","country":"United Kingdom","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":675,"brand":"1886 Malt House","name":" NY 2-Row Base Malt","country":"American","lovibond":2.7,"ppg":35.64,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":638,"brand":"1886 Malt House","name":" NY 2-Row Pale Malt","country":"American","lovibond":1.8,"ppg":35.64,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":672,"brand":"1886 Malt House","name":"Caramel 40","country":"American","lovibond":40,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":673,"brand":"1886 Malt House","name":"Caromatic 180","country":"American","lovibond":180,"ppg":0,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":682,"brand":"1886 Malt House","name":"NY 6-Row Base Malt","country":"American","lovibond":2.3,"ppg":34.78,"mashable":1,"category":"Grain","diastatic":109,"graintype":"base malt"},
  {"id":683,"brand":"1886 Malt House","name":"NY Aromatic 20","country":"American","lovibond":20,"ppg":34.86,"mashable":1,"category":"Grain","diastatic":20,"graintype":"roasted malt"},
  {"id":1062,"brand":"1886 Malt House","name":"NY Caramel 20","country":"American","lovibond":20,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":676,"brand":"1886 Malt House","name":"NY Pale Malt","country":"American","lovibond":3.5,"ppg":35.64,"mashable":1,"category":"Grain","diastatic":118,"graintype":"base malt"},
  {"id":674,"brand":"1886 Malt House","name":"NY Pilsner Malt","country":"American","lovibond":1.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":645,"brand":"1886 Malt House","name":"NY Red Winter Wheat","country":"American","lovibond":1.9,"ppg":35.499,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":677,"brand":"1886 Malt House","name":"NY Synergy Base Malt","country":"American","lovibond":2.6,"ppg":36.11,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":678,"brand":"1886 Malt House","name":"NY Munich Malt","country":"US","lovibond":5,"ppg":34.8,"mashable":1,"category":"Grain","diastatic":105,"graintype":"specialty malt"},
  {"id":1610,"brand":"Admiral","name":"Feldblume","country":"American","lovibond":2.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1864,"brand":"Admiral","name":"Gallagher's Best","country":"American","lovibond":2.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2067,"brand":"Admiral","name":"Kilnsmith","country":"American","lovibond":70,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":568,"brand":"Admiral","name":"Organic Pilsner Malt","country":"American","lovibond":1.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2064,"brand":"Admiral","name":"Pacific Victor","country":"American","lovibond":6,"ppg":37,"mashable":1,"category":"Grain","diastatic":95,"graintype":"base malt"},
  {"id":1241,"brand":"Admiral","name":"Pils (Pilsner)","country":"American","lovibond":1.9,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":707,"brand":"Admiral","name":"Yolo Gold Wheat","country":"American","lovibond":3,"ppg":39,"mashable":1,"category":"Grain","diastatic":143,"graintype":"base malt"},
  {"id":2066,"brand":"Admiral","name":"Admiral's Hearth","country":"US","lovibond":20,"ppg":37,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":863,"brand":"Admiral","name":"Maiden Voyage","country":"US","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2065,"brand":"Admiral","name":"Midway","country":"US","lovibond":10,"ppg":37,"mashable":1,"category":"Grain","diastatic":83,"graintype":"specialty malt"},
  {"id":2278,"brand":"Agr\u00e1ria","name":"Munich Malt","country":"BR","lovibond":9,"ppg":36,"mashable":1,"category":"Grain","diastatic":47,"graintype":"specialty malt"},
  {"id":2275,"brand":"Agr\u00e1ria","name":"Pale Ale Malt","country":"BR","lovibond":2.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":2276,"brand":"Agr\u00e1ria","name":"Pilsner Malt","country":"BR","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":2277,"brand":"Agr\u00e1ria","name":"Vienna Malt","country":"BR","lovibond":4,"ppg":37,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":2410,"brand":"Avangard","name":"Caramel Light 8L","country":"DE","lovibond":8,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":873,"brand":"Avangard","name":"Crystal 10L","country":"DE","lovibond":8,"ppg":38.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":872,"brand":"Avangard","name":"Light Munich","country":"DE","lovibond":8,"ppg":37,"mashable":1,"category":"Grain","diastatic":72,"graintype":"specialty malt"},
  {"id":419,"brand":"Avangard","name":"Munich Dark","country":"DE","lovibond":12,"ppg":37.1,"mashable":1,"category":"Grain","diastatic":50,"graintype":"specialty malt"},
  {"id":602,"brand":"Avangard","name":"Dark Caramel Malt","country":"German","lovibond":28,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1012,"brand":"Avangard","name":"Pale 2-Row","country":"German","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":130,"graintype":"base malt"},
  {"id":601,"brand":"Avangard","name":"Pale Ale Malt","country":"German","lovibond":2,"ppg":39,"mashable":1,"category":"Grain","diastatic":273,"graintype":"base malt"},
  {"id":869,"brand":"Avangard","name":"Pilsner","country":"German","lovibond":1.7,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":67,"graintype":"base malt"},
  {"id":870,"brand":"Avangard","name":"Vienna","country":"German","lovibond":3.5,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":871,"brand":"Avangard","name":"Wheat","country":"German","lovibond":2,"ppg":38.2,"mashable":1,"category":"Grain","diastatic":74,"graintype":"base malt"},
  {"id":2125,"brand":"BA Malt","name":"Biscuit","country":"AR","lovibond":20,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2138,"brand":"BA Malt","name":"Carapils","country":"AR","lovibond":4,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2131,"brand":"BA Malt","name":"Melanoidina","country":"AR","lovibond":25,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2124,"brand":"BA Malt","name":"Munich","country":"AR","lovibond":7,"ppg":38,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":2132,"brand":"BA Malt","name":"Brown","country":"Argentina","lovibond":130,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2129,"brand":"BA Malt","name":"Caramelo 120","country":"Argentina","lovibond":120,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2130,"brand":"BA Malt","name":"Caramelo 140","country":"Argentina","lovibond":140,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2126,"brand":"BA Malt","name":"Caramelo 15","country":"Argentina","lovibond":15,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2127,"brand":"BA Malt","name":"Caramelo 30","country":"Argentina","lovibond":30,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2128,"brand":"BA Malt","name":"Caramelo 60","country":"Argentina","lovibond":60,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2133,"brand":"BA Malt","name":"Cebada Tostada","country":"Argentina","lovibond":500,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2134,"brand":"BA Malt","name":"M Chocolate","country":"Argentina","lovibond":400,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2135,"brand":"BA Malt","name":"M Tostada","country":"Argentina","lovibond":500,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2122,"brand":"BA Malt","name":"Pale Ale","country":"Argentina","lovibond":4,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2121,"brand":"BA Malt","name":"Pilsen","country":"Argentina","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":2137,"brand":"BA Malt","name":"Roasted Wheat","country":"Argentina","lovibond":475,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2123,"brand":"BA Malt","name":"Vienna","country":"Argentina","lovibond":5,"ppg":38,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":2136,"brand":"BA Malt","name":"Wheat Malt","country":"Argentina","lovibond":2,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2072,"brand":"Bairds","name":"Dextrin Malt","country":"GB","lovibond":1.75,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2359,"brand":"Bairds","name":"English Carastan Malt","country":"GB","lovibond":35,"ppg":35.4,"mashable":0,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1621,"brand":"Bairds","name":"Greenwich Crystal Malt","country":"GB","lovibond":75,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2441,"brand":"Bairds","name":"London Lager Malt","country":"GB","lovibond":1.7,"ppg":37,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":2349,"brand":"Bairds","name":"Maris Otter Finest Ale Malt ","country":"GB","lovibond":2.5,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":55,"graintype":"base malt"},
  {"id":1340,"brand":"Bairds","name":"Medium Caramel / Crystal 80L","country":"GB","lovibond":80,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2202,"brand":"Bairds","name":"Medium Crystal","country":"GB","lovibond":75,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2071,"brand":"Bairds","name":"Munich Malt","country":"GB","lovibond":5,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":50,"graintype":"specialty malt"},
  {"id":1968,"brand":"Bairds","name":"Roasted Barley","country":"GB","lovibond":600,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1643,"brand":"Bairds","name":"Chocolate Malt","country":"United Kingdom","lovibond":500,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":633,"brand":"Bairds","name":"Crystal Malt 150","country":"United Kingdom","lovibond":57,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":543,"brand":"Bairds","name":"Maris Otter Pale Ale","country":"United Kingdom","lovibond":2.5,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":45,"graintype":"base malt"},
  {"id":2170,"brand":"Bairds","name":"Pale Ale","country":"United Kingdom","lovibond":2.5,"ppg":37.2,"mashable":1,"category":"Grain","diastatic":45,"graintype":"base malt"},
  {"id":2069,"brand":"Bairds","name":"Pilsen/Lager","country":"United Kingdom","lovibond":1.5,"ppg":37.2,"mashable":1,"category":"Grain","diastatic":65,"graintype":"base malt"},
  {"id":2070,"brand":"Bairds","name":"Vienna Malt","country":"United Kingdom","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":910,"brand":"Barnowl","name":"Munich Malt II","country":"CA","lovibond":10.5,"ppg":32,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1016,"brand":"Barnowl","name":"Pale Ale Malt","country":"Canadian","lovibond":3.3,"ppg":35,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":865,"brand":"Barnowl","name":"Pilsener Malt","country":"Canadian","lovibond":1.7,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":866,"brand":"Barnowl","name":"Soft Red Wheat Malt","country":"Canadian","lovibond":2.5,"ppg":32,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1017,"brand":"Barnowl","name":"Vienna","country":"Canadian","lovibond":6,"ppg":34,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":2213,"brand":"Barrett Burston","name":"Dark Munich Malt","country":"AU","lovibond":10,"ppg":35,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":2212,"brand":"Barrett Burston","name":"Munich Malt","country":"AU","lovibond":7.7,"ppg":35,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":670,"brand":"Barrett Burston","name":"Ale Malt","country":"Australian","lovibond":2.8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":1550,"brand":"Barrett Burston","name":"Cara malt","country":"Australian","lovibond":17.5,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2214,"brand":"Barrett Burston","name":"Dark Crystal Malt","country":"Australian","lovibond":80,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":589,"brand":"Barrett Burston","name":"Pale Malt","country":"Australian","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":230,"graintype":"base malt"},
  {"id":2215,"brand":"Barrett Burston","name":"Roasted Barley","country":"Australian","lovibond":340,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2211,"brand":"Barrett Burston","name":"Vienna Malt","country":"Australian","lovibond":4.3,"ppg":35,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1324,"brand":"Barrett Burston","name":"Wheat Malt","country":"Australian","lovibond":1.8,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":2399,"brand":"Barrett Burston","name":"Pilsner Malt","country":"NZ","lovibond":1.92893,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":1752,"brand":"Bestmalz","name":"BEST Caramel Munich III","country":"DE","lovibond":63,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":618,"brand":"Bestmalz","name":"BEST Melanoidin","country":"DE","lovibond":27,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":1946,"brand":"Bestmalz","name":"BEST Melanoidin Light","country":"DE","lovibond":19.5,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":398,"brand":"Bestmalz","name":"BEST Munich","country":"DE","lovibond":6.3,"ppg":37,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":832,"brand":"Bestmalz","name":"BEST Munich Dark ","country":"DE","lovibond":11,"ppg":36.23,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":364,"brand":"Bestmalz","name":"Best RED X","country":"DE","lovibond":12,"ppg":36,"mashable":1,"category":"Grain","diastatic":68,"graintype":"specialty malt"},
  {"id":896,"brand":"Bestmalz","name":"BEST Smoked","country":"DE","lovibond":2.5,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":76,"graintype":"smoked malt"},
  {"id":692,"brand":"Bestmalz","name":"BEST Acidulated","country":"German","lovibond":2.80943,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"acidulated malt"},
  {"id":666,"brand":"Bestmalz","name":"BEST Biscuit","country":"German","lovibond":20,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":655,"brand":"Bestmalz","name":"BEST Black Malt","country":"German","lovibond":425,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":656,"brand":"Bestmalz","name":"BEST Black Malt eXtra","country":"German","lovibond":500,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":734,"brand":"Bestmalz","name":"BEST Caramel Amber","country":"German","lovibond":25,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":362,"brand":"Bestmalz","name":"BEST Caramel Aromatic","country":"German","lovibond":19,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":890,"brand":"Bestmalz","name":"BEST Caramel Hell","country":"German","lovibond":5.4,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":619,"brand":"Bestmalz","name":"BEST Caramel Munich I","country":"German","lovibond":35,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1943,"brand":"Bestmalz","name":"BEST Caramel Munich II","country":"German","lovibond":45.5,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":693,"brand":"Bestmalz","name":"BEST Caramel Pils","country":"German","lovibond":2.4347,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":592,"brand":"Bestmalz","name":"BEST Chit Malt","country":"German","lovibond":1.4,"ppg":23,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1065,"brand":"Bestmalz","name":"BEST Chocolate","country":"German","lovibond":337.819,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":528,"brand":"Bestmalz","name":"BEST Heidelberg","country":"German","lovibond":1.3,"ppg":38,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1155,"brand":"Bestmalz","name":"BEST Heidelberg Wheat Malt","country":"German","lovibond":1.6,"ppg":37,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1947,"brand":"Bestmalz","name":"BEST Organic Pilsen Malt","country":"German","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":691,"brand":"Bestmalz","name":"BEST Pale ale","country":"German","lovibond":2.80943,"ppg":38.1,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1944,"brand":"Bestmalz","name":"BEST Peated","country":"German","lovibond":2.5,"ppg":35.8,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":617,"brand":"Bestmalz","name":"BEST Pilsen","country":"German","lovibond":1.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":250,"graintype":"base malt"},
  {"id":657,"brand":"Bestmalz","name":"BEST Roasted Barley","country":"German","lovibond":480,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":499,"brand":"Bestmalz","name":"BEST Special X","country":"German","lovibond":132.5,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":897,"brand":"Bestmalz","name":"BEST Spelt","country":"German","lovibond":2.2,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":351,"brand":"Bestmalz","name":"BEST Vienna","country":"German","lovibond":3.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":399,"brand":"Bestmalz","name":"BEST Wheat Malt","country":"German","lovibond":2.2,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1945,"brand":"Bestmalz","name":"BEST Wheat Malt Dark","country":"German","lovibond":7.3,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":350,"brand":"Bestmalz","name":"Rye Malt","country":"German","lovibond":3.1,"ppg":38,"mashable":1,"category":"Grain","diastatic":105,"graintype":"base malt"},
  {"id":402,"brand":"Bestmalz","name":"Salome Lager Malt","country":"German","lovibond":2.1,"ppg":37,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":813,"brand":"Blacklands","name":"Pale Moon 2-Row","country":"American","lovibond":4,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1018,"brand":"Blacklands","name":"Silver Star 2-row","country":"American","lovibond":4,"ppg":35,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":811,"brand":"Blacklands","name":"Brownfield 15 Munich","country":"US","lovibond":15,"ppg":35,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":810,"brand":"Blacklands","name":"Brownfield 25 Munich","country":"US","lovibond":25,"ppg":35,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":812,"brand":"Blacklands","name":"Brownfield 40 Munich","country":"US","lovibond":40,"ppg":35,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":781,"brand":"Blue Ox Malthouse","name":"Yankee Pilsner","country":"American","lovibond":1.4,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":578,"brand":"Brewferm","name":"Pilsner","country":"German","lovibond":1,"ppg":37,"mashable":1,"category":"Grain","diastatic":16,"graintype":"base malt"},
  {"id":22,"brand":"Briess","name":"Bonlander Munich","country":"","lovibond":10,"ppg":36,"mashable":1,"category":"Grain","diastatic":40,"graintype":"specialty malt"},
  {"id":955,"brand":"Briess","name":"Black Barley","country":"American","lovibond":500,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":956,"brand":"Briess","name":"Black Malt - 2-Row","country":"American","lovibond":500,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":957,"brand":"Briess","name":"Blackprinz Malt","country":"American","lovibond":500,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":960,"brand":"Briess","name":"Brewers Malt 2-Row","country":"American","lovibond":1.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":966,"brand":"Briess","name":"Carabrown Malt","country":"American","lovibond":55,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":120,"graintype":"crystal malt"},
  {"id":967,"brand":"Briess","name":"Caracrystal Wheat Malt","country":"American","lovibond":55,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":120,"graintype":"crystal malt"},
  {"id":968,"brand":"Briess","name":"Caramel Malt - 10L","country":"American","lovibond":10,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":969,"brand":"Briess","name":"Caramel Malt - 120L","country":"American","lovibond":120,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":970,"brand":"Briess","name":"Caramel Malt - 20L","country":"American","lovibond":20,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":971,"brand":"Briess","name":"Caramel Malt - 30L","country":"American","lovibond":30,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":972,"brand":"Briess","name":"Caramel Malt - 40L","country":"American","lovibond":40,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":973,"brand":"Briess","name":"Caramel Malt - 60L","country":"American","lovibond":60,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":974,"brand":"Briess","name":"Caramel Malt - 80L","country":"American","lovibond":80,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":975,"brand":"Briess","name":"Caramel Malt - 90L","country":"American","lovibond":90,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":976,"brand":"Briess","name":"Caramel Munich 60L","country":"American","lovibond":60,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":70,"graintype":"crystal malt"},
  {"id":424,"brand":"Briess","name":"Caramel Rye","country":"American","lovibond":60,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":978,"brand":"Briess","name":"Caramel Vienne 20L Malt","country":"American","lovibond":20,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":375,"brand":"Briess","name":"Carapils Copper","country":"American","lovibond":30,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":982,"brand":"Briess","name":"Chocolate","country":"American","lovibond":350,"ppg":25,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":822,"brand":"Briess","name":"Dark Chocolate Malt","country":"American","lovibond":420,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":983,"brand":"Briess","name":"Distillers Malt","country":"American","lovibond":2.4,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":250,"graintype":"base malt"},
  {"id":497,"brand":"Briess","name":"Extra Special Malt","country":"American","lovibond":130,"ppg":33.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":984,"brand":"Briess","name":"Full Pint Brewers Malt","country":"American","lovibond":1.7,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":150,"graintype":"base malt"},
  {"id":985,"brand":"Briess","name":"Goldpils Vienna Malt","country":"American","lovibond":3.5,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":987,"brand":"Briess","name":"Midnight Wheat Malt","country":"American","lovibond":550,"ppg":25,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":988,"brand":"Briess","name":"Organic Black Malt","country":"American","lovibond":500,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":990,"brand":"Briess","name":"Organic Brewers Malt","country":"American","lovibond":1.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":991,"brand":"Briess","name":"Organic Caramel Malt - 120L","country":"American","lovibond":120,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":992,"brand":"Briess","name":"Organic Caramel Malt - 20L","country":"American","lovibond":20,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":993,"brand":"Briess","name":"Organic Caramel Malt - 60L","country":"American","lovibond":60,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":995,"brand":"Briess","name":"Organic Chocolate Malt","country":"American","lovibond":350,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":996,"brand":"Briess","name":"Organic Roasted Barley","country":"American","lovibond":300,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":997,"brand":"Briess","name":"Pale Ale Malt 2-Row","country":"American","lovibond":3.5,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":85,"graintype":"base malt"},
  {"id":998,"brand":"Briess","name":"Pilsen Malt 2-Row","country":"American","lovibond":1.2,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":999,"brand":"Briess","name":"Red Wheat Raw","country":"American","lovibond":2,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1001,"brand":"Briess","name":"Roasted Barley","country":"American","lovibond":300,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1002,"brand":"Briess","name":"Rye Malt","country":"American","lovibond":3.7,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":105,"graintype":"base malt"},
  {"id":1003,"brand":"Briess","name":"Rye Raw","country":"American","lovibond":3,"ppg":35.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":686,"brand":"Briess","name":"Special Roast","country":"American","lovibond":40,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1004,"brand":"Briess","name":"Synergy Select Pilsen Malt","country":"American","lovibond":1.8,"ppg":38.3,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":542,"brand":"Briess","name":"Victory Malt","country":"American","lovibond":28,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1005,"brand":"Briess","name":"Wheat Malt, Red","country":"American","lovibond":2.3,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":180,"graintype":"base malt"},
  {"id":1006,"brand":"Briess","name":"Wheat Malt, White","country":"American","lovibond":2.5,"ppg":39.1,"mashable":1,"category":"Grain","diastatic":160,"graintype":"base malt"},
  {"id":1007,"brand":"Briess","name":"White Wheat Raw","country":"American","lovibond":2,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2652,"brand":"Briess","name":"American Honey Malt","country":"US","lovibond":25,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":951,"brand":"Briess","name":"Apple Wood Smoked Malt","country":"US","lovibond":6,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"smoked malt"},
  {"id":952,"brand":"Briess","name":"Aromatic Munich Malt 20L","country":"US","lovibond":20,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":20,"graintype":"specialty malt"},
  {"id":2398,"brand":"Briess","name":"Ashburne\u00ae Mild Malt","country":"US","lovibond":5.3,"ppg":36.34,"mashable":1,"category":"Grain","diastatic":65,"graintype":"base malt"},
  {"id":1437,"brand":"Briess","name":"Blonde RoastOat Malt","country":"US","lovibond":6,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":958,"brand":"Briess","name":"Bonlander Munich Malt 10L","country":"US","lovibond":10,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":40,"graintype":"specialty malt"},
  {"id":979,"brand":"Briess","name":"Carapils Copper Malt","country":"US","lovibond":30,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":980,"brand":"Briess","name":"Carapils Malt","country":"US","lovibond":1.5,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":981,"brand":"Briess","name":"Cherry Wood Smoked Malt","country":"US","lovibond":5,"ppg":37,"mashable":1,"category":"Grain","diastatic":90,"graintype":"smoked malt"},
  {"id":466,"brand":"Briess","name":"Dark Munich Malt 30L","country":"US","lovibond":30,"ppg":36,"mashable":1,"category":"Grain","diastatic":40,"graintype":"specialty malt"},
  {"id":2651,"brand":"Briess","name":"MaltGems","country":"US","lovibond":1.8,"ppg":38.3,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":986,"brand":"Briess","name":"Mesquite Smoked Malt","country":"US","lovibond":5,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"smoked malt"},
  {"id":989,"brand":"Briess","name":"Organic Bonlander Munich Malt 10L","country":"US","lovibond":10,"ppg":35,"mashable":1,"category":"Grain","diastatic":30,"graintype":"specialty malt"},
  {"id":994,"brand":"Briess","name":"Organic Carapils Malt","country":"US","lovibond":1.5,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":689,"brand":"BSG","name":"Perla Negra","country":"US","lovibond":340,"ppg":32,"mashable":1,"category":"Grain","diastatic":2,"graintype":"roasted malt"},
  {"id":2088,"brand":"Canada Malting","name":"Century Rye","country":"CA","lovibond":2.65,"ppg":29,"mashable":1,"category":"Grain","diastatic":105,"graintype":"base malt"},
  {"id":1603,"brand":"Canada Malting","name":"CMC 2-ROW MALT","country":"CA","lovibond":1.75,"ppg":36,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":2220,"brand":"Canada Malting","name":"MUNICH MALT","country":"CA","lovibond":10.5,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":2219,"brand":"Canada Malting","name":"OAT MALT","country":"CA","lovibond":2.6,"ppg":27.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2221,"brand":"Canada Malting","name":"BOURBON MALT","country":"Canadian","lovibond":2.2,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":250,"graintype":"base malt"},
  {"id":2216,"brand":"Canada Malting","name":"CMC 6-ROW MALT","country":"Canadian","lovibond":2.2,"ppg":37,"mashable":1,"category":"Grain","diastatic":150,"graintype":"base malt"},
  {"id":2223,"brand":"Canada Malting","name":"HERITAGE SELECT MALT","country":"Canadian","lovibond":2.2,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":150,"graintype":"base malt"},
  {"id":2225,"brand":"Canada Malting","name":"MARITIME MALT","country":"Canadian","lovibond":2.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2227,"brand":"Canada Malting","name":"NEW ENGLANDER MALT","country":"Canadian","lovibond":2.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2224,"brand":"Canada Malting","name":"ONTARIO SELECT MALT","country":"Canadian","lovibond":2.8,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2217,"brand":"Canada Malting","name":"ORGANIC 2-ROW MALT","country":"Canadian","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2222,"brand":"Canada Malting","name":"POT STILL MALT","country":"Canadian","lovibond":1.5,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":250,"graintype":"base malt"},
  {"id":2226,"brand":"Canada Malting","name":"QU\u00c9B\u00c9COISE MALT","country":"Canadian","lovibond":2.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1869,"brand":"Canada Malting","name":"SUPERIOR PALE ALE MALT","country":"Canadian","lovibond":3.1,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2218,"brand":"Canada Malting","name":"SUPERIOR PILSEN MALT","country":"Canadian","lovibond":1.5,"ppg":37.2,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":534,"brand":"Canada Malting","name":"WHITE WHEAT MALT","country":"Canadian","lovibond":3.5,"ppg":38.1,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":425,"brand":"Castle Malting","name":"Ch\u00e2teau Biscuit","country":"BE","lovibond":17,"ppg":35,"mashable":1,"category":"Grain","diastatic":40,"graintype":"specialty malt"},
  {"id":867,"brand":"Castle Malting","name":"Ch\u00e2teau Black","country":"BE","lovibond":488,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1090,"brand":"Castle Malting","name":"Chateau Diastatic","country":"BE","lovibond":2.05997,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1040,"brand":"Castle Malting","name":"Ch\u00e2teau Melano","country":"BE","lovibond":31,"ppg":35.88,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":481,"brand":"Castle Malting","name":"Ch\u00e2teau Munich","country":"BE","lovibond":12.7,"ppg":34,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":482,"brand":"Castle Malting","name":"Ch\u00e2teau Munich Light","country":"BE","lovibond":7.6,"ppg":34,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1594,"brand":"Castle Malting","name":"Chateau Oat Malt","country":"BE","lovibond":2.4,"ppg":36.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":874,"brand":"Castle Malting","name":"Ch\u00e2teau Rye Malt (Malta De Centeno)","country":"BE","lovibond":3.6,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":805,"brand":"Castle Malting","name":"Ch\u00e2teau Smoked (Malta Ahumada)","country":"BE","lovibond":2.05997,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":76,"graintype":"smoked malt"},
  {"id":806,"brand":"Castle Malting","name":"Amber","country":"Belgian","lovibond":17.4239,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":76,"graintype":"roasted malt"},
  {"id":440,"brand":"Castle Malting","name":"Ch\u00e2teau Abbey","country":"Belgian","lovibond":17.5,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":511,"brand":"Castle Malting","name":"Ch\u00e2teau Acid (Malta Acida)","country":"Belgian","lovibond":4.1,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"acidulated malt"},
  {"id":875,"brand":"Castle Malting","name":"Ch\u00e2teau Arome","country":"Belgian","lovibond":39.8,"ppg":34.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":504,"brand":"Castle Malting","name":"Ch\u00e2teau Cafe","country":"Belgian","lovibond":176.8,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":513,"brand":"Castle Malting","name":"Ch\u00e2teau Cafe Light","country":"Belgian","lovibond":90,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":665,"brand":"Castle Malting","name":"Ch\u00e2teau Cara Blond","country":"Belgian","lovibond":8,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":600,"brand":"Castle Malting","name":"Ch\u00e2teau Cara Clair","country":"Belgian","lovibond":3.2,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":680,"brand":"Castle Malting","name":"Ch\u00e2teau Cara Gold","country":"Belgian","lovibond":45,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":799,"brand":"Castle Malting","name":"Ch\u00e2teau Cara Ruby","country":"Belgian","lovibond":19.3,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":514,"brand":"Castle Malting","name":"Ch\u00e2teau Chocolat","country":"Belgian","lovibond":488.1,"ppg":38,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":876,"brand":"Castle Malting","name":"Ch\u00e2teau Crystal","country":"Belgian","lovibond":60.6,"ppg":34.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":591,"brand":"Castle Malting","name":"Ch\u00e2teau Monastique","country":"Belgian","lovibond":17.5,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":518,"brand":"Castle Malting","name":"Ch\u00e2teau Pale Ale","country":"Belgian","lovibond":3.4,"ppg":38,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":708,"brand":"Castle Malting","name":"Ch\u00e2teau Pilsen 2RS","country":"Belgian","lovibond":1.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":483,"brand":"Castle Malting","name":"Ch\u00e2teau Roasted Barley","country":"Belgian","lovibond":432,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":519,"brand":"Castle Malting","name":"Ch\u00e2teau Special Belgium","country":"Belgian","lovibond":115,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":679,"brand":"Castle Malting","name":"Ch\u00e2teau Vienna","country":"Belgian","lovibond":2.6,"ppg":38,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":710,"brand":"Castle Malting","name":"Ch\u00e2teau Wheat Blanc","country":"Belgian","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":74,"graintype":"base malt"},
  {"id":438,"brand":"Castle Malting","name":"Ch\u00e2teau Whiskey Light","country":"Belgian","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":0,"graintype":"other"},
  {"id":450,"brand":"Coopers","name":"Premium Pale Malt","country":"AU","lovibond":3.2,"ppg":37,"mashable":1,"category":"Grain","diastatic":200,"graintype":"base malt"},
  {"id":475,"brand":"Coopers","name":"Pale Malt","country":"Australian","lovibond":2.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":200,"graintype":"base malt"},
  {"id":917,"brand":"Crisp Malting","name":"Caramalt 15L","country":"GB","lovibond":17.5,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":631,"brand":"Crisp Malting","name":"Chocolate Malt","country":"GB","lovibond":450,"ppg":32.66,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":606,"brand":"Crisp Malting","name":"Dark Munich","country":"GB","lovibond":19,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":13,"graintype":"specialty malt"},
  {"id":2401,"brand":"Crisp Malting","name":"Extra Pale Ale Malt","country":"GB","lovibond":1.7,"ppg":38,"mashable":1,"category":"Grain","diastatic":145,"graintype":"base malt"},
  {"id":1777,"brand":"Crisp Malting","name":"Hana Malt","country":"GB","lovibond":1.3,"ppg":31,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":712,"brand":"Crisp Malting","name":"Munich Malt","country":"GB","lovibond":10.0005,"ppg":37,"mashable":1,"category":"Grain","diastatic":35,"graintype":"specialty malt"},
  {"id":502,"brand":"Crisp Malting","name":"Roasted Barley","country":"GB","lovibond":520,"ppg":31.28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1463,"brand":"Crisp Malting","name":"Rye Malt","country":"GB","lovibond":11.803,"ppg":39.8,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":913,"brand":"Crisp Malting","name":"Amber","country":"United Kingdom","lovibond":27.5,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":914,"brand":"Crisp Malting","name":"Best Ale","country":"United Kingdom","lovibond":3,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":55,"graintype":"base malt"},
  {"id":915,"brand":"Crisp Malting","name":"Black","country":"United Kingdom","lovibond":600,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":916,"brand":"Crisp Malting","name":"Brown Malt","country":"United Kingdom","lovibond":65,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":455,"brand":"Crisp Malting","name":"Cara Gold malt","country":"United Kingdom","lovibond":14,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":358,"brand":"Crisp Malting","name":"Caramalt","country":"United Kingdom","lovibond":15,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":918,"brand":"Crisp Malting","name":"Chevallier Heritage Malt","country":"United Kingdom","lovibond":3,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":1915,"brand":"Crisp Malting","name":"Clear Choice Malt","country":"United Kingdom","lovibond":2.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":919,"brand":"Crisp Malting","name":"Crystal 60L","country":"United Kingdom","lovibond":60,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":920,"brand":"Crisp Malting","name":"Crystal Dark - 77L","country":"United Kingdom","lovibond":77,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":921,"brand":"Crisp Malting","name":"Crystal Extra Dark - 120L","country":"United Kingdom","lovibond":120,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":922,"brand":"Crisp Malting","name":"Crystal Light - 45L","country":"United Kingdom","lovibond":45,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":616,"brand":"Crisp Malting","name":"Dextrin Malt","country":"United Kingdom","lovibond":1.8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":453,"brand":"Crisp Malting","name":"Europils Malt","country":"United Kingdom","lovibond":1.87,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":66,"graintype":"base malt"},
  {"id":517,"brand":"Crisp Malting","name":"Extra Pale Maris Otter","country":"United Kingdom","lovibond":2,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":47,"graintype":"base malt"},
  {"id":923,"brand":"Crisp Malting","name":"Finest Maris Otter","country":"United Kingdom","lovibond":3,"ppg":38,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":924,"brand":"Crisp Malting","name":"Gleneagles Premium Peated Malt","country":"United Kingdom","lovibond":3,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2006,"brand":"Crisp Malting","name":"No.19 Floor Malt Maris Otter","country":"United Kingdom","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":1353,"brand":"Crisp Malting","name":"Organic Extra Pale Malt","country":"United Kingdom","lovibond":1.8,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":926,"brand":"Crisp Malting","name":"Pale Ale","country":"United Kingdom","lovibond":4,"ppg":38,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":927,"brand":"Crisp Malting","name":"Pale Chocolate","country":"United Kingdom","lovibond":220,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":110,"graintype":"roasted malt"},
  {"id":713,"brand":"Crisp Malting","name":"Pale Wheat Malt","country":"United Kingdom","lovibond":2.00002,"ppg":38.7,"mashable":1,"category":"Grain","diastatic":130,"graintype":"base malt"},
  {"id":928,"brand":"Crisp Malting","name":"Torrefied Wheat","country":"United Kingdom","lovibond":3,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2236,"brand":"Crisp Malting","name":"Vienna Malt","country":"United Kingdom","lovibond":3.3,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":454,"brand":"Crisp Malting","name":"Wheat Malt","country":"United Kingdom","lovibond":2,"ppg":38.87,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1645,"brand":"Deer Creek","name":"Colonial Pilsner","country":"American","lovibond":1.8,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":135,"graintype":"base malt"},
  {"id":1649,"brand":"Deer Creek","name":"Keystone Pale","country":"American","lovibond":2.9,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1650,"brand":"Deer Creek","name":"Pale Rye","country":"American","lovibond":4,"ppg":38.6,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1651,"brand":"Deer Creek","name":"Pale Spelt","country":"American","lovibond":3,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1652,"brand":"Deer Creek","name":"Pale Triticale","country":"American","lovibond":4,"ppg":38.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1653,"brand":"Deer Creek","name":"Pale Wheat","country":"American","lovibond":3.5,"ppg":38.1,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1654,"brand":"Deer Creek","name":"Sweet Liberty","country":"American","lovibond":6,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":1655,"brand":"Deer Creek","name":"Twilight Wheat","country":"American","lovibond":8.5,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1646,"brand":"Deer Creek","name":"Double Dutch","country":"US","lovibond":20,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":25,"graintype":"specialty malt"},
  {"id":1647,"brand":"Deer Creek","name":"Dutch","country":"US","lovibond":10,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":45,"graintype":"specialty malt"},
  {"id":1648,"brand":"Deer Creek","name":"First Crack (Melanoidin)","country":"US","lovibond":45,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":1593,"brand":"Dingemans","name":"Aromatic Malt","country":"BE","lovibond":19,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2424,"brand":"Dingemans","name":"Belgian Cara 20 (Caravienne) Malt","country":"BE","lovibond":23,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2352,"brand":"Dingemans","name":"Belgian Cara 8 Pils Malt","country":"BE","lovibond":6,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2351,"brand":"Dingemans","name":"Belgian Pilsner Malt","country":"BE","lovibond":1.6,"ppg":37,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":611,"brand":"Dingemans","name":"Biscuit","country":"BE","lovibond":22,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":385,"brand":"Dingemans","name":"Chocolate - mroost 1400","country":"BE","lovibond":711,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":432,"brand":"Dingemans","name":"Munich MD","country":"BE","lovibond":6.3,"ppg":36.5,"mashable":1,"category":"Grain","diastatic":50,"graintype":"specialty malt"},
  {"id":403,"brand":"Dingemans","name":"Pale Ale ","country":"BE","lovibond":3.9,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":439,"brand":"Dingemans","name":"Cara 120 MD","country":"Belgian","lovibond":120,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1107,"brand":"Dingemans","name":"Cara 45 ","country":"Belgian","lovibond":47,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1108,"brand":"Dingemans","name":"De-bittered Black Malt","country":"Belgian","lovibond":550,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":418,"brand":"Dingemans","name":"Pilsen","country":"Belgian","lovibond":1.7,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":704,"brand":"Dingemans","name":"Special B","country":"Belgian","lovibond":125,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1525,"brand":"Dingemans","name":"Wheat Malt","country":"Belgian","lovibond":1.8,"ppg":38.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":767,"brand":"Eckert Malting","name":"Biscuit Rice Malt","country":"American","lovibond":4.8,"ppg":22,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":641,"brand":"Eckert Malting","name":"Crystal Rice Malt","country":"American","lovibond":16,"ppg":22,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":637,"brand":"Eckert Malting","name":"Pale Rice Malt","country":"American","lovibond":1.4,"ppg":22,"mashable":1,"category":"Grain","diastatic":41,"graintype":"gluten-free malt"},
  {"id":535,"brand":"Franco-Belges","name":"Kiln Amber (Biscuit)","country":"BE","lovibond":17,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":587,"brand":"Franco-Belges","name":"Special Aromatic","country":"BE","lovibond":4,"ppg":37,"mashable":1,"category":"Grain","diastatic":100,"graintype":"specialty malt"},
  {"id":621,"brand":"Franco-Belges","name":"Aromatic Malt","country":"Belgian","lovibond":3,"ppg":35,"mashable":1,"category":"Grain","diastatic":15,"graintype":"acidulated malt"},
  {"id":549,"brand":"Franco-Belges","name":"Caramel Munich 60","country":"Belgian","lovibond":60,"ppg":34,"mashable":1,"category":"Grain","diastatic":70,"graintype":"crystal malt"},
  {"id":551,"brand":"Franco-Belges","name":"Caramel Munich 80","country":"Belgian","lovibond":80,"ppg":34,"mashable":1,"category":"Grain","diastatic":70,"graintype":"crystal malt"},
  {"id":537,"brand":"Franco-Belges","name":"CaraVienna","country":"Belgian","lovibond":20,"ppg":27,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":381,"brand":"Franco-Belges","name":"Kiln Coffee","country":"Belgian","lovibond":150,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":413,"brand":"Franco-Belges","name":"Pilsen Malt","country":"Belgian","lovibond":1.7,"ppg":39,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":1306,"brand":"Gambrinus","name":"Honey Malt","country":"CA","lovibond":25,"ppg":37,"mashable":1,"category":"Grain","diastatic":50,"graintype":"crystal malt"},
  {"id":1995,"brand":"Gambrinus","name":"Munich - Light 10L","country":"CA","lovibond":10,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1996,"brand":"Gambrinus","name":"Munich Dark","country":"CA","lovibond":30,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1990,"brand":"Gambrinus","name":"ESB Pale Malt","country":"Canadian","lovibond":3,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1910,"brand":"Gambrinus","name":"Pale Malt","country":"Canadian","lovibond":2.1,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2012,"brand":"Gambrinus","name":"Pilsner Malt","country":"Canadian","lovibond":1.5,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1999,"brand":"Gambrinus","name":"Wheat Malt","country":"Canadian","lovibond":2,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2049,"brand":"Gladfield","name":"American Ale Malt","country":"New Zealand","lovibond":2.53807,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":2050,"brand":"Gladfield","name":"Big O, Malted Oats","country":"New Zealand","lovibond":2.28426,"ppg":27.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2636,"brand":"Gladfield","name":"Big-o Malted Oats","country":"New Zealand","lovibond":2.03046,"ppg":27,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2637,"brand":"Gladfield","name":"Black Forrest Rye Malt","country":"New Zealand","lovibond":44.6701,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1280,"brand":"Gladfield","name":"Chocolate Malt","country":"New Zealand","lovibond":488,"ppg":24,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2043,"brand":"Gladfield","name":"Crystal Rye Malt","country":"New Zealand","lovibond":35.533,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2041,"brand":"Gladfield","name":"Dark Crystal Malt","country":"New Zealand","lovibond":96.4467,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2040,"brand":"Gladfield","name":"Distillers Malt","country":"New Zealand","lovibond":2.03046,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2638,"brand":"Gladfield","name":"Flaked Barley (harraway's)","country":"New Zealand","lovibond":1.67513,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2639,"brand":"Gladfield","name":"Flaked Wheat (harraway's)","country":"New Zealand","lovibond":1.62437,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2640,"brand":"Gladfield","name":"German Pilsener Malt","country":"New Zealand","lovibond":2.03046,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":2039,"brand":"Gladfield","name":"German Pilsner Malt","country":"New Zealand","lovibond":2.03046,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2038,"brand":"Gladfield","name":"Gladiator Malt","country":"New Zealand","lovibond":5.07614,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2641,"brand":"Gladfield","name":"Go Nutty Roasted Oats","country":"New Zealand","lovibond":4.82233,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2642,"brand":"Gladfield","name":"Golden Naked Oats","country":"New Zealand","lovibond":1.42132,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2037,"brand":"Gladfield","name":"Lager Light","country":"New Zealand","lovibond":1.4264,"ppg":37,"mashable":1,"category":"Grain","diastatic":79,"graintype":"base malt"},
  {"id":2035,"brand":"Gladfield","name":"Light Crystal Malt","country":"New Zealand","lovibond":31.9797,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2033,"brand":"Gladfield","name":"Medium Crystal Malt","country":"New Zealand","lovibond":56.3452,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2031,"brand":"Gladfield","name":"Organic Pilsner","country":"New Zealand","lovibond":1.58629,"ppg":37.9,"mashable":1,"category":"Grain","diastatic":45,"graintype":"base malt"},
  {"id":2643,"brand":"Gladfield","name":"Peat Smoked (heavy)","country":"New Zealand","lovibond":2.18274,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":2030,"brand":"Gladfield","name":"Pilsner Malt","country":"New Zealand","lovibond":1.92893,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":2026,"brand":"Gladfield","name":"Roasted Wheat","country":"New Zealand","lovibond":279.188,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":2644,"brand":"Gladfield","name":"Rolled Oats (blm)","country":"New Zealand","lovibond":2.79188,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2645,"brand":"Gladfield","name":"Rolled Oats (harraway's)","country":"New Zealand","lovibond":1.62437,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2646,"brand":"Gladfield","name":"Rolled Wheat (blm)","country":"New Zealand","lovibond":1.62437,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2025,"brand":"Gladfield","name":"Rye Malt","country":"New Zealand","lovibond":3,"ppg":40.2,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2020,"brand":"Gladfield","name":"Vienna Malt","country":"New Zealand","lovibond":3.45178,"ppg":37.4,"mashable":1,"category":"Grain","diastatic":71,"graintype":"base malt"},
  {"id":2019,"brand":"Gladfield","name":"Wheat Malt","country":"New Zealand","lovibond":2.13198,"ppg":38.8,"mashable":1,"category":"Grain","diastatic":73,"graintype":"base malt"},
  {"id":2048,"brand":"Gladfield","name":"Aurora Malt","country":"NZ","lovibond":29.4416,"ppg":37.2,"mashable":1,"category":"Grain","diastatic":57,"graintype":"specialty malt"},
  {"id":2047,"brand":"Gladfield","name":"Biscuit Malt","country":"NZ","lovibond":30.4569,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2046,"brand":"Gladfield","name":"Brown Malt","country":"NZ","lovibond":90.3553,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2045,"brand":"Gladfield","name":"Chit Malt","country":"NZ","lovibond":1.52284,"ppg":15,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2044,"brand":"Gladfield","name":"Chocolate Rye Malt","country":"NZ","lovibond":406.091,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2042,"brand":"Gladfield","name":"Dark Chocolate Malt","country":"NZ","lovibond":488,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2036,"brand":"Gladfield","name":"Light Chocolate Malt","country":"NZ","lovibond":355,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2034,"brand":"Gladfield","name":"Manuka Smoked Malt","country":"NZ","lovibond":2.03046,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":120,"graintype":"smoked malt"},
  {"id":2032,"brand":"Gladfield","name":"Munich Malt","country":"NZ","lovibond":7.86802,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":74,"graintype":"specialty malt"},
  {"id":2029,"brand":"Gladfield","name":"Red Back Malt","country":"NZ","lovibond":32.9949,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2028,"brand":"Gladfield","name":"Red Back Wheat","country":"NZ","lovibond":13.7056,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":2027,"brand":"Gladfield","name":"Roast Barley","country":"NZ","lovibond":507,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2024,"brand":"Gladfield","name":"Shepherds Delight Malt","country":"NZ","lovibond":120,"ppg":35.4,"mashable":1,"category":"Grain","diastatic":47,"graintype":"specialty malt"},
  {"id":2023,"brand":"Gladfield","name":"Sour Grapes Malt Acid","country":"NZ","lovibond":2.03046,"ppg":12.4,"mashable":1,"category":"Grain","diastatic":120,"graintype":"acidulated malt"},
  {"id":2022,"brand":"Gladfield","name":"Supernova Malt","country":"NZ","lovibond":58.3756,"ppg":36.5,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":2021,"brand":"Gladfield","name":"Toffee Malt","country":"NZ","lovibond":5.32995,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1974,"brand":"Great Western ","name":"Crystal 40 Malt","country":"","lovibond":40,"ppg":21,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1473,"brand":"Great Western ","name":"Brumalt","country":"American","lovibond":20,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":697,"brand":"Great Western ","name":"California Select 2-Row Malt","country":"American","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":135,"graintype":"base malt"},
  {"id":699,"brand":"Great Western ","name":"Domestic 2-Row","country":"American","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":135,"graintype":"base malt"},
  {"id":1083,"brand":"Great Western ","name":"Northwest Pale Ale Malt","country":"American","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":135,"graintype":"base malt"},
  {"id":408,"brand":"Great Western ","name":"Pale High Color","country":"American","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":16,"graintype":"base malt"},
  {"id":861,"brand":"Great Western ","name":"Premium 2-Row","country":"American","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":160,"graintype":"base malt"},
  {"id":891,"brand":"Great Western ","name":"Pure Idaho Pilsen","country":"American","lovibond":1.5,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1699,"brand":"Great Western ","name":"Superior Pilsen (1.6 \u00b0L)","country":"American","lovibond":1.6,"ppg":35,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1260,"brand":"Great Western ","name":"Vienna","country":"American","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":701,"brand":"Great Western ","name":"White Wheat","country":"American","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":188,"graintype":"base malt"},
  {"id":1880,"brand":"Great Western ","name":"Organic Premium 2-Row","country":"CA","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2367,"brand":"Great Western ","name":"Dark Munich","country":"US","lovibond":20,"ppg":33,"mashable":1,"category":"Grain","diastatic":20,"graintype":"specialty malt"},
  {"id":414,"brand":"Great Western ","name":"DextraPils","country":"US","lovibond":1.5,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2314,"brand":"Great Western ","name":"Mela Malt","country":"US","lovibond":30,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1881,"brand":"Great Western ","name":"Organic White Wheat","country":"US","lovibond":3.5,"ppg":27.6,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":768,"brand":"Grouse Malt House","name":"American Roasted Millet Malt","country":"American","lovibond":16,"ppg":25,"mashable":1,"category":"Grain","diastatic":22,"graintype":"gluten-free malt"},
  {"id":639,"brand":"Grouse Malt House","name":"Buckwheat Malt","country":"American","lovibond":2,"ppg":30,"mashable":1,"category":"Grain","diastatic":25,"graintype":"gluten-free malt"},
  {"id":614,"brand":"Grouse Malt House","name":"CaraMillet Malt","country":"American","lovibond":2.8,"ppg":28,"mashable":1,"category":"Grain","diastatic":25,"graintype":"gluten-free malt"},
  {"id":580,"brand":"Grouse Malt House","name":"Crystal Millet Malt","country":"American","lovibond":2.5,"ppg":20,"mashable":1,"category":"Grain","diastatic":25,"graintype":"gluten-free malt"},
  {"id":1535,"brand":"Grouse Malt House","name":"Dark Millet Malt","country":"American","lovibond":325,"ppg":29,"mashable":1,"category":"Grain","diastatic":30,"graintype":"gluten-free malt"},
  {"id":1588,"brand":"Grouse Malt House","name":"French Roast","country":"American","lovibond":30,"ppg":20,"mashable":1,"category":"Grain","diastatic":20,"graintype":"gluten-free malt"},
  {"id":769,"brand":"Grouse Malt House","name":"Goldfinch Millet Malt","country":"American","lovibond":5.4,"ppg":28,"mashable":1,"category":"Grain","diastatic":22,"graintype":"gluten-free malt"},
  {"id":640,"brand":"Grouse Malt House","name":"Munich Millet Malt","country":"American","lovibond":3,"ppg":28,"mashable":1,"category":"Grain","diastatic":25,"graintype":"gluten-free malt"},
  {"id":1810,"brand":"Grouse Malt House","name":"Organic Roasted Buckwheat Malt","country":"American","lovibond":42,"ppg":30,"mashable":1,"category":"Grain","diastatic":30,"graintype":"gluten-free malt"},
  {"id":761,"brand":"Grouse Malt House","name":"Pale Millet Malt","country":"American","lovibond":2.4,"ppg":29,"mashable":1,"category":"Grain","diastatic":25,"graintype":"gluten-free malt"},
  {"id":642,"brand":"Grouse Malt House","name":"Roasted CaraMillet Malt","country":"American","lovibond":25,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":1589,"brand":"Grouse Malt House","name":"Vienna Millet Malt","country":"American","lovibond":2.6,"ppg":31,"mashable":1,"category":"Grain","diastatic":20,"graintype":"gluten-free malt"},
  {"id":1607,"brand":"Grouse Malt House","name":"Chocolate Roasted Millet Malt","country":"US","lovibond":140,"ppg":22,"mashable":1,"category":"Grain","diastatic":0,"graintype":"gluten-free malt"},
  {"id":1865,"brand":"Grouse Malt House","name":"Red Wing Amber Millet Malt","country":"US","lovibond":22,"ppg":24,"mashable":1,"category":"Grain","diastatic":30,"graintype":"gluten-free malt"},
  {"id":2541,"brand":"Grouse Malt House","name":"Roasted Goldfinch Millet Malt","country":"US","lovibond":5.4,"ppg":28,"mashable":1,"category":"Grain","diastatic":25,"graintype":"gluten-free malt"},
  {"id":1319,"brand":"Haus Malts","name":"Pale","country":"American","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":389,"brand":"Haus Malts","name":"Triticale","country":"American","lovibond":3,"ppg":30,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1916,"brand":"House of Malt","name":"Munich Malt","country":"AU","lovibond":7,"ppg":37,"mashable":1,"category":"Grain","diastatic":150,"graintype":"specialty malt"},
  {"id":1834,"brand":"House of Malt","name":"Pilsner Malt","country":"Australian","lovibond":2.1,"ppg":34.6,"mashable":1,"category":"Grain","diastatic":302,"graintype":"base malt"},
  {"id":1960,"brand":"Hudson Valley Malt","name":"Pale 2-Row","country":"US","lovibond":1.4,"ppg":33,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1236,"brand":"Ireks","name":"Aroma Malt","country":"DE","lovibond":107.5,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1963,"brand":"Ireks","name":"Crystal Maple","country":"DE","lovibond":2.1,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1820,"brand":"Ireks","name":"Munich Malt","country":"DE","lovibond":8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":516,"brand":"Ireks","name":"Pilsner Malt","country":"DE","lovibond":1.8,"ppg":36,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1684,"brand":"Ireks","name":"Black Malt","country":"German","lovibond":530,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1829,"brand":"Ireks","name":"Chocolate","country":"German","lovibond":340,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1779,"brand":"Ireks","name":"Crystal Oak","country":"German","lovibond":12.5,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1681,"brand":"Ireks","name":"Crystal Teak","country":"German","lovibond":35,"ppg":55,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1852,"brand":"Ireks","name":"Pale Ale Malt","country":"German","lovibond":2.8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":525,"brand":"Ireks","name":"Vienna","country":"German","lovibond":2.5,"ppg":34,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1695,"brand":"Ireks","name":"Wheat Malt Black","country":"German","lovibond":700,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1686,"brand":"Ireks","name":"Wheat Malt Dark","country":"German","lovibond":6.5,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1830,"brand":"Ireks","name":"Wheat Malt Light","country":"German","lovibond":1.95,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1843,"brand":"Joe White","name":"Light Munich","country":"AU","lovibond":5.25,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":47,"graintype":"specialty malt"},
  {"id":1433,"brand":"Joe White","name":"Pilsner / Export Pilsner","country":"AU","lovibond":2,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1482,"brand":"Joe White","name":"Ale Malt","country":"Australian","lovibond":2.81,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":61,"graintype":"base malt"},
  {"id":1523,"brand":"Joe White","name":"Amber","country":"Australian","lovibond":20,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1769,"brand":"Joe White","name":"Caramalt","country":"Australian","lovibond":21.17,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1077,"brand":"Joe White","name":"Chocolate Malt","country":"Australian","lovibond":244,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1483,"brand":"Joe White","name":"Dark Crystal Malt","country":"Australian","lovibond":85,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1840,"brand":"Joe White","name":"Dark Toast","country":"Australian","lovibond":487.71,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1841,"brand":"Joe White","name":"Extra Dark Crystal","country":"Australian","lovibond":103.61,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1842,"brand":"Joe White","name":"Light Crystal Malt","country":"Australian","lovibond":45.53,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1844,"brand":"Joe White","name":"Light Roast","country":"Australian","lovibond":337.82,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1845,"brand":"Joe White","name":"Medium Crystal","country":"Australian","lovibond":56.77,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1274,"brand":"Joe White","name":"Pale Malt","country":"Australian","lovibond":1.9,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":33,"graintype":"base malt"},
  {"id":1074,"brand":"Joe White","name":"Roasted Barley","country":"Australian","lovibond":475,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1846,"brand":"Joe White","name":"Roasted Wheat Malt","country":"Australian","lovibond":524.06,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":120,"graintype":"roasted malt"},
  {"id":1766,"brand":"Joe White","name":"Vienna Malt","country":"Australian","lovibond":3.37,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":47,"graintype":"base malt"},
  {"id":1767,"brand":"Joe White","name":"Wheat Malt","country":"Australian","lovibond":2.15,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":84,"graintype":"base malt"},
  {"id":2073,"brand":"LINC Malt","name":"99 Triticale Pale","country":"","lovibond":4.5,"ppg":40.9,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1837,"brand":"LINC Malt","name":"99 Triticale Pilsner","country":"American","lovibond":3.75,"ppg":38.4,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1772,"brand":"LINC Malt","name":"Baroness Crystal 60L","country":"American","lovibond":60,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1771,"brand":"LINC Malt","name":"Baronesse Caramel/Dextrine","country":"American","lovibond":3.75,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1619,"brand":"LINC Malt","name":"Baronesse Dark Munich","country":"American","lovibond":60,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":60,"graintype":"crystal malt"},
  {"id":1290,"brand":"LINC Malt","name":"Baronesse English Pale","country":"American","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1790,"brand":"LINC Malt","name":"Baronesse Pale","country":"American","lovibond":1.4,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":664,"brand":"LINC Malt","name":"Baronnesse c40","country":"American","lovibond":42.8,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":663,"brand":"LINC Malt","name":"Baronnesse Pilsner","country":"American","lovibond":1.65,"ppg":37,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":1345,"brand":"LINC Malt","name":"Cashup White Wheat Pale","country":"American","lovibond":2.2,"ppg":38.2,"mashable":1,"category":"Grain","diastatic":118,"graintype":"base malt"},
  {"id":1698,"brand":"LINC Malt","name":"Francin Pilsner","country":"American","lovibond":1.65,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1770,"brand":"LINC Malt","name":"Gazelle Rye Pale","country":"American","lovibond":5,"ppg":35.1,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1289,"brand":"LINC Malt","name":"Lyon English Pale","country":"American","lovibond":3.3,"ppg":37,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1719,"brand":"LINC Malt","name":"Lyon Pale","country":"American","lovibond":1.85,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1742,"brand":"LINC Malt","name":"Lyon Pilsner, Organic","country":"American","lovibond":1.5,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1496,"brand":"LINC Malt","name":"Purple Egyptian Pale","country":"American","lovibond":3.5,"ppg":36.1,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1659,"brand":"LINC Malt","name":"Red Russian Wheat Pale","country":"American","lovibond":2.75,"ppg":36.4,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1508,"brand":"LINC Malt","name":"Scots Bere Pale","country":"American","lovibond":3.5,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1495,"brand":"LINC Malt","name":"Scots Bere Pilsner","country":"American","lovibond":2,"ppg":33.8,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1494,"brand":"LINC Malt","name":"Sonora White Wheat Pale","country":"American","lovibond":1.6,"ppg":35.7,"mashable":1,"category":"Grain","diastatic":83,"graintype":"base malt"},
  {"id":1696,"brand":"LINC Malt","name":"Spelt Pale","country":"American","lovibond":4.25,"ppg":25.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1786,"brand":"LINC Malt","name":"99 Triticale Munich","country":"US","lovibond":14.5,"ppg":40,"mashable":1,"category":"Grain","diastatic":78,"graintype":"specialty malt"},
  {"id":1721,"brand":"LINC Malt","name":"Baronesse Crystal 120L","country":"US","lovibond":120,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1898,"brand":"LINC Malt","name":"Baronesse Vienna","country":"US","lovibond":5.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":662,"brand":"LINC Malt","name":"Baronnesse Light Munich","country":"US","lovibond":7.5,"ppg":36,"mashable":1,"category":"Grain","diastatic":65,"graintype":"specialty malt"},
  {"id":1768,"brand":"LINC Malt","name":"Copeland English Pale","country":"US","lovibond":4,"ppg":37.2,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1816,"brand":"LINC Malt","name":"Copeland Munich","country":"US","lovibond":35,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1720,"brand":"LINC Malt","name":"Lyon Light Munich","country":"US","lovibond":6.5,"ppg":36,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1918,"brand":"Makers Malt","name":"Caramel 60","country":"Canadian","lovibond":45,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1917,"brand":"Makers Malt","name":"Premium Pale Malt - 2-row","country":"Canadian","lovibond":2.05,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":135,"graintype":"base malt"},
  {"id":745,"brand":"Maltear","name":"Caramelo 120","country":"American","lovibond":120,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":749,"brand":"Maltear","name":"Caramelo 60","country":"American","lovibond":60,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":748,"brand":"Maltear","name":"Chocolate","country":"American","lovibond":300,"ppg":29,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":747,"brand":"Maltear","name":"Negra","country":"American","lovibond":560,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":750,"brand":"Maltear","name":"Pilsener","country":"American","lovibond":2,"ppg":38,"mashable":1,"category":"Grain","diastatic":71,"graintype":"base malt"},
  {"id":746,"brand":"Maltear","name":"Carapils","country":"US","lovibond":1,"ppg":35,"mashable":1,"category":"Grain","diastatic":65,"graintype":"crystal malt"},
  {"id":1929,"brand":"Malteurop","name":"Red Wheat","country":"American","lovibond":2.5,"ppg":32,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1456,"brand":"Mecca Grade","name":"Gateway","country":"","lovibond":1.2,"ppg":38,"mashable":1,"category":"Grain","diastatic":230,"graintype":"base malt"},
  {"id":369,"brand":"Mecca Grade","name":"Lamonta (American Pale)","country":"American","lovibond":3,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1912,"brand":"Mecca Grade","name":"Pale 2-Row","country":"American","lovibond":2.8,"ppg":30,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":383,"brand":"Mecca Grade","name":"Pelton","country":"American","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":157,"graintype":"base malt"},
  {"id":1671,"brand":"Mecca Grade","name":"Rimrock (Vienna-style Spring Rye)","country":"American","lovibond":4.8,"ppg":40,"mashable":1,"category":"Grain","diastatic":130,"graintype":"base malt"},
  {"id":384,"brand":"Mecca Grade","name":"Shaniko (White Winter Wheat)","country":"American","lovibond":3.1,"ppg":39,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":370,"brand":"Mecca Grade","name":"Vanora (Vienna-style)","country":"American","lovibond":7.2,"ppg":36,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1332,"brand":"Mecca Grade","name":"Wickiup (Hard Red Spring Wheat)","country":"American","lovibond":3.3,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":1469,"brand":"Mecca Grade","name":"Metolius [Munich Style]","country":"US","lovibond":8.7,"ppg":35,"mashable":1,"category":"Grain","diastatic":110,"graintype":"specialty malt"},
  {"id":694,"brand":"Mecca Grade","name":"Opal 22","country":"US","lovibond":22,"ppg":35.6,"mashable":1,"category":"Grain","diastatic":75,"graintype":"specialty malt"},
  {"id":1479,"brand":"Mecca Grade","name":"Opal 44","country":"US","lovibond":45,"ppg":36,"mashable":1,"category":"Grain","diastatic":45,"graintype":"specialty malt"},
  {"id":743,"brand":"Muntons","name":"Oat Malt","country":"GB","lovibond":2.4347,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":742,"brand":"Muntons","name":"Cara Malt","country":"United Kingdom","lovibond":11.0535,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":357,"brand":"Muntons","name":"Crystal 60","country":"United Kingdom","lovibond":55,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":356,"brand":"Muntons","name":"Dark Crystal","country":"United Kingdom","lovibond":150,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":426,"brand":"Muntons","name":"Maris Otter","country":"United Kingdom","lovibond":2.3,"ppg":38,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":741,"brand":"Muntons","name":"Pale Planet","country":"United Kingdom","lovibond":2.28481,"ppg":38.25,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1932,"brand":"New York Craft Malts","name":"Saranac Special Dark Oats","country":"US","lovibond":125,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1086,"brand":"Niagara Malt","name":"Munich Light","country":"US","lovibond":10,"ppg":25,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1084,"brand":"Niagara Malt","name":"Pale Ale Malt","country":"US","lovibond":2.5,"ppg":35,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1085,"brand":"Niagara Malt","name":"Pilsner","country":"US","lovibond":1.8,"ppg":35,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1087,"brand":"Niagara Malt","name":"Spelt Malt","country":"US","lovibond":2,"ppg":20,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":2307,"brand":"Niagara Malt","name":"Vienna","country":"US","lovibond":3.2,"ppg":30,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1958,"brand":"OiO","name":"Canadian 2-Row Malt","country":"Canadian","lovibond":1.75,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":903,"brand":"Patagonia","name":"Caramel 140L","country":"Chile","lovibond":140,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":594,"brand":"Patagonia","name":"Caramel 190L","country":"Chile","lovibond":190,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":595,"brand":"Patagonia","name":"Caramel 55L","country":"Chile","lovibond":55,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":596,"brand":"Patagonia","name":"Caramel 90L","country":"Chile","lovibond":90,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1111,"brand":"Patagonia","name":"Extra Pale Malt","country":"Chile","lovibond":1.8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":1815,"brand":"Patagonia","name":"Black Pearl","country":"CL","lovibond":340,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2057,"brand":"Patagonia","name":"Patagonia Munich","country":"CL","lovibond":12,"ppg":34,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":2054,"brand":"Patagonia","name":"Patagonia Pale ale","country":"CL","lovibond":2.9,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":2055,"brand":"Patagonia","name":"Patagonia Vienna","country":"CL","lovibond":2.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":786,"brand":"Proximity","name":"Base Malt","country":"American","lovibond":2,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1802,"brand":"Proximity","name":"Biscuit Roasted 20","country":"American","lovibond":20,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1941,"brand":"Proximity","name":"Black Malt","country":"American","lovibond":500,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":787,"brand":"Proximity","name":"Crystal 15","country":"American","lovibond":15,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":789,"brand":"Proximity","name":"Crystal 80","country":"American","lovibond":80,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1428,"brand":"Proximity","name":"Pale Ale Malt","country":"American","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":1825,"brand":"Proximity","name":"Pale Chocolate","country":"American","lovibond":300,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":788,"brand":"Proximity","name":"Pilsen Malt","country":"American","lovibond":1.8,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":145,"graintype":"base malt"},
  {"id":1914,"brand":"Proximity","name":"Prox Mild","country":"American","lovibond":6,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":1851,"brand":"Proximity","name":"Roasted Barley","country":"American","lovibond":475,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":842,"brand":"Proximity","name":"Super Extract Base","country":"American","lovibond":2,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":2374,"brand":"Proximity","name":"Caramunich 60","country":"US","lovibond":60,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2373,"brand":"Proximity","name":"Caravienne 20","country":"US","lovibond":20,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1942,"brand":"Proximity","name":"Chocolate Malt","country":"US","lovibond":350,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2384,"brand":"Proximity","name":"Chocolate Rye","country":"US","lovibond":225,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2382,"brand":"Proximity","name":"Chocolate Wheat","country":"US","lovibond":400,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2378,"brand":"Proximity","name":"Crystal 120","country":"US","lovibond":120,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2379,"brand":"Proximity","name":"Crystal 150","country":"US","lovibond":150,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2376,"brand":"Proximity","name":"Crystal 30","country":"US","lovibond":30,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2377,"brand":"Proximity","name":"Crystal 40","country":"US","lovibond":40,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":790,"brand":"Proximity","name":"Crystal 60","country":"US","lovibond":60,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2383,"brand":"Proximity","name":"Crystal Rye","country":"US","lovibond":80,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2380,"brand":"Proximity","name":"Crystal Wheat","country":"US","lovibond":60,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2381,"brand":"Proximity","name":"Dark Chocolate","country":"US","lovibond":400,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2375,"brand":"Proximity","name":"Dextrine Malt","country":"US","lovibond":3,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2148,"brand":"Proximity","name":"Malted Oats","country":"US","lovibond":2.5,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":2447,"brand":"Proximity","name":"Mid-Atlantic Wheat","country":"US","lovibond":3,"ppg":39,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":791,"brand":"Proximity","name":"Munich Malt","country":"US","lovibond":10,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":50,"graintype":"specialty malt"},
  {"id":2370,"brand":"Proximity","name":"Organic Base","country":"US","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":95,"graintype":"base malt"},
  {"id":2389,"brand":"Proximity","name":"Pale Malt","country":"US","lovibond":2,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":2385,"brand":"Proximity","name":"Pale Roasted Barley","country":"US","lovibond":475,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2371,"brand":"Proximity","name":"Prox Red","country":"US","lovibond":18,"ppg":36,"mashable":1,"category":"Grain","diastatic":80,"graintype":"crystal malt"},
  {"id":2372,"brand":"Proximity","name":"Rye Malt","country":"US","lovibond":4,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1368,"brand":"Proximity","name":"Vienna","country":"US","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":1173,"brand":"Proximity","name":"Wheat Malt","country":"US","lovibond":3,"ppg":39.1,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":2183,"brand":"Rahr","name":"High DP Distillers Malt","country":"American","lovibond":2.75,"ppg":35,"mashable":1,"category":"Grain","diastatic":220,"graintype":"base malt"},
  {"id":2185,"brand":"Rahr","name":"Kilned Caramel 30L","country":"American","lovibond":30,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2186,"brand":"Rahr","name":"Kilned Caramel 47L","country":"American","lovibond":47,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2187,"brand":"Rahr","name":"Kilned Caramel 80L","country":"American","lovibond":80,"ppg":27.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2184,"brand":"Rahr","name":"Old World Pilsner","country":"American","lovibond":1.75,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":115,"graintype":"base malt"},
  {"id":2180,"brand":"Rahr","name":"Pale Ale","country":"American","lovibond":3.5,"ppg":36.34,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":1364,"brand":"Rahr","name":"Premium Pilsner","country":"American","lovibond":1.75,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":895,"brand":"Rahr","name":"Red Wheat","country":"American","lovibond":3.2,"ppg":39.1,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":354,"brand":"Rahr","name":"Standard 2-Row","country":"American","lovibond":1.8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":130,"graintype":"base malt"},
  {"id":353,"brand":"Rahr","name":"Standard 6-Row","country":"American","lovibond":2.3,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":160,"graintype":"base malt"},
  {"id":1730,"brand":"Rahr","name":"White Wheat","country":"American","lovibond":3.2,"ppg":39.1,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":777,"brand":"Riverbend Malt","name":"Appalachian Wheat","country":"American","lovibond":2.6,"ppg":39,"mashable":1,"category":"Grain","diastatic":132,"graintype":"base malt"},
  {"id":1471,"brand":"Riverbend Malt","name":"Base Camp Extra Pale","country":"American","lovibond":1.9,"ppg":36.4,"mashable":1,"category":"Grain","diastatic":141,"graintype":"base malt"},
  {"id":844,"brand":"Riverbend Malt","name":"Heritage","country":"American","lovibond":4.4,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1038,"brand":"Riverbend Malt","name":"Pale Malt","country":"American","lovibond":2.3,"ppg":36.4,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":803,"brand":"Riverbend Malt","name":"Pilsner","country":"American","lovibond":1.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":156,"graintype":"base malt"},
  {"id":776,"brand":"Riverbend Malt","name":"Southern Select","country":"American","lovibond":2.6,"ppg":38,"mashable":1,"category":"Grain","diastatic":138,"graintype":"base malt"},
  {"id":1458,"brand":"Riverbend Malt","name":"Vienna","country":"American","lovibond":2.7,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1501,"brand":"Riverbend Malt","name":"Dark Munich","country":"US","lovibond":37,"ppg":38,"mashable":1,"category":"Grain","diastatic":81,"graintype":"specialty malt"},
  {"id":1352,"brand":"Riverbend Malt","name":"Malted Corn","country":"US","lovibond":1.7,"ppg":37,"mashable":1,"category":"Grain","diastatic":26,"graintype":"base malt"},
  {"id":1459,"brand":"Riverbend Malt","name":"Melanoidin Malt","country":"US","lovibond":23.82,"ppg":35,"mashable":1,"category":"Grain","diastatic":37,"graintype":"specialty malt"},
  {"id":779,"brand":"Riverbend Malt","name":"Munich - Light 10L","country":"US","lovibond":10,"ppg":37,"mashable":1,"category":"Grain","diastatic":81,"graintype":"specialty malt"},
  {"id":778,"brand":"Riverbend Malt","name":"Streaker Malted Oats","country":"US","lovibond":1.5,"ppg":39,"mashable":1,"category":"Grain","diastatic":117,"graintype":"base malt"},
  {"id":556,"brand":"Root Shoot Malting","name":"Crystal 100","country":"American","lovibond":100,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1452,"brand":"Root Shoot Malting","name":"Distiller's Malt","country":"American","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":165,"graintype":"base malt"},
  {"id":557,"brand":"Root Shoot Malting","name":"Genie Honey","country":"American","lovibond":9,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":554,"brand":"Root Shoot Malting","name":"Genie Pale 2-Row","country":"American","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":109,"graintype":"base malt"},
  {"id":563,"brand":"Root Shoot Malting","name":"Genie Vienna ","country":"American","lovibond":7,"ppg":36,"mashable":1,"category":"Grain","diastatic":66,"graintype":"base malt"},
  {"id":565,"brand":"Root Shoot Malting","name":"Malted Rye","country":"American","lovibond":4.25,"ppg":40,"mashable":1,"category":"Grain","diastatic":127,"graintype":"base malt"},
  {"id":561,"brand":"Root Shoot Malting","name":"Metcalfe Pale Ale","country":"American","lovibond":4,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":1451,"brand":"Root Shoot Malting","name":"Pale Ale Malt","country":"American","lovibond":4.6,"ppg":36,"mashable":1,"category":"Grain","diastatic":133,"graintype":"base malt"},
  {"id":555,"brand":"Root Shoot Malting","name":"Pilsner","country":"American","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":98,"graintype":"base malt"},
  {"id":566,"brand":"Root Shoot Malting","name":"White Wheat","country":"American","lovibond":2.4,"ppg":38,"mashable":1,"category":"Grain","diastatic":146,"graintype":"base malt"},
  {"id":559,"brand":"Root Shoot Malting","name":"Genie Munich","country":"US","lovibond":3,"ppg":35,"mashable":1,"category":"Grain","diastatic":105,"graintype":"specialty malt"},
  {"id":560,"brand":"Root Shoot Malting","name":"Odyssey Munich","country":"US","lovibond":20,"ppg":37,"mashable":1,"category":"Grain","diastatic":39,"graintype":"specialty malt"},
  {"id":569,"brand":"Sekado","name":"Sekado Czech  2-Row","country":"","lovibond":3,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":571,"brand":"Sekado","name":"Munich","country":"DE","lovibond":20,"ppg":20,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1997,"brand":"Simpsons","name":"Amber Malt","country":"GB","lovibond":20.8,"ppg":31.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":770,"brand":"Simpsons","name":"Aromatic Malt","country":"GB","lovibond":23.04,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1294,"brand":"Simpsons","name":"Chocolate Malt","country":"GB","lovibond":444,"ppg":31.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2228,"brand":"Simpsons","name":"Crystal Extra Dark","country":"GB","lovibond":178.558,"ppg":31.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1327,"brand":"Simpsons","name":"Dextrin Malt","country":"GB","lovibond":1.1,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":540,"brand":"Simpsons","name":"Golden Naked Oats","country":"GB","lovibond":7,"ppg":27,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":853,"brand":"Simpsons","name":"Munich Malt","country":"GB","lovibond":8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":25,"graintype":"specialty malt"},
  {"id":2567,"brand":"Simpsons","name":"Peated Malt","country":"GB","lovibond":1.5,"ppg":37.674,"mashable":1,"category":"Grain","diastatic":0,"graintype":"smoked malt"},
  {"id":526,"brand":"Simpsons","name":"Wheat Malt","country":"GB","lovibond":2.8,"ppg":36.576,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":508,"brand":"Simpsons","name":"Best Pale Ale","country":"United Kingdom","lovibond":2,"ppg":37.5,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":397,"brand":"Simpsons","name":"Black Malt","country":"United Kingdom","lovibond":700,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1931,"brand":"Simpsons","name":"Brown Malt","country":"United Kingdom","lovibond":187.926,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":597,"brand":"Simpsons","name":"CaraMalt","country":"United Kingdom","lovibond":14,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":598,"brand":"Simpsons","name":"CaraMalt Light","country":"United Kingdom","lovibond":12,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":607,"brand":"Simpsons","name":"Crisp Amber Malt","country":"United Kingdom","lovibond":27,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":620,"brand":"Simpsons","name":"Crystal Dark","country":"United Kingdom","lovibond":120,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1019,"brand":"Simpsons","name":"Crystal Light ","country":"United Kingdom","lovibond":38.0341,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":773,"brand":"Simpsons","name":"Crystal Medium","country":"United Kingdom","lovibond":65.0147,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":839,"brand":"Simpsons","name":"Crystal T50","country":"United Kingdom","lovibond":50,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":373,"brand":"Simpsons","name":"DRC","country":"United Kingdom","lovibond":115,"ppg":34.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":877,"brand":"Simpsons","name":"Extra Pale Ale Malt","country":"United Kingdom","lovibond":2.2,"ppg":35.82,"mashable":1,"category":"Grain","diastatic":65,"graintype":"base malt"},
  {"id":878,"brand":"Simpsons","name":"Finest Lager Malt","country":"United Kingdom","lovibond":2.2,"ppg":35.74,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":411,"brand":"Simpsons","name":"Finest Pale Ale Golden Promise","country":"United Kingdom","lovibond":2.4,"ppg":37,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":474,"brand":"Simpsons","name":"Finest Pale Ale Maris Otter","country":"United Kingdom","lovibond":2.6,"ppg":37,"mashable":1,"category":"Grain","diastatic":67,"graintype":"base malt"},
  {"id":695,"brand":"Simpsons","name":"Heritage Crystal","country":"United Kingdom","lovibond":66,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1803,"brand":"Simpsons","name":"Imperial Malt","country":"United Kingdom","lovibond":15.6,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":349,"brand":"Simpsons","name":"Light Crystal","country":"United Kingdom","lovibond":39,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1422,"brand":"Simpsons","name":"Low Colour Maris Otter","country":"United Kingdom","lovibond":1.72272,"ppg":37,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":1488,"brand":"Simpsons","name":"Maris Otter Extra Pale","country":"United Kingdom","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1131,"brand":"Simpsons","name":"Maris Otter pale","country":"United Kingdom","lovibond":2.4,"ppg":38,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":772,"brand":"Simpsons","name":"Oat Malt","country":"United Kingdom","lovibond":2,"ppg":28,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":912,"brand":"Simpsons","name":"Pale 2-Row","country":"United Kingdom","lovibond":1.8,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1138,"brand":"Simpsons","name":"Premium English Caramalt","country":"United Kingdom","lovibond":23.0449,"ppg":32.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1135,"brand":"Simpsons","name":"Red Rye Crystal","country":"United Kingdom","lovibond":89.4,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1957,"brand":"Simpsons","name":"Roasted Barley","country":"United Kingdom","lovibond":600,"ppg":28.52,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":881,"brand":"Simpsons","name":"Vienna","country":"United Kingdom","lovibond":3.5,"ppg":36,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":359,"brand":"Skagit Valley Malting","name":"Copeland Pale","country":"American","lovibond":2,"ppg":33,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1680,"brand":"Stone Path Malt","name":"Nor\u2019East Munich","country":"DE","lovibond":6,"ppg":36.8,"mashable":0,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":1689,"brand":"Stone Path Malt","name":"Nor\u2019East Vienna","country":"DE","lovibond":3.5,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":1791,"brand":"Stone Path Malt","name":"Nor'East Gold","country":"US","lovibond":2,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":1687,"brand":"Stone Path Malt","name":"Nor\u2019East Pale","country":"US","lovibond":2.9,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":1134,"brand":"The Swaen","name":"Lager Malt","country":"GB","lovibond":1.4,"ppg":38,"mashable":1,"category":"Grain","diastatic":67,"graintype":"base malt"},
  {"id":718,"brand":"The Swaen","name":"BlackSwaen Chocolate B","country":"Netherlands","lovibond":338,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":719,"brand":"The Swaen","name":"Goldswaen Hell","country":"Netherlands","lovibond":9.9,"ppg":0,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":547,"brand":"The Swaen","name":"Swaen Ale","country":"Netherlands","lovibond":3.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":250,"graintype":"base malt"},
  {"id":546,"brand":"The Swaen","name":"Swaen Pilsner","country":"Netherlands","lovibond":2,"ppg":38,"mashable":1,"category":"Grain","diastatic":250,"graintype":"base malt"},
  {"id":751,"brand":"The Swaen","name":"Ale Malt","country":"NL","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":140,"graintype":"base malt"},
  {"id":840,"brand":"The Swaen","name":"BlackSwaen Barley","country":"NL","lovibond":400,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1567,"brand":"The Swaen","name":"BlackSwaen Biscuit","country":"NL","lovibond":30,"ppg":36,"mashable":1,"category":"Grain","diastatic":60,"graintype":"roasted malt"},
  {"id":2425,"brand":"The Swaen","name":"Blackswaen Black","country":"NL","lovibond":125,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1197,"brand":"The Swaen","name":"Blackswaen Black Extra","country":"NL","lovibond":500,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1894,"brand":"The Swaen","name":"Blackswaen Black W","country":"NL","lovibond":400,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2426,"brand":"The Swaen","name":"Blackswaen Chocolate W","country":"NL","lovibond":350,"ppg":35.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2427,"brand":"The Swaen","name":"Blackswaen Coffee Light W","country":"NL","lovibond":150,"ppg":35.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":802,"brand":"The Swaen","name":"BlackSwaen Coffee Malt","country":"NL","lovibond":225,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1630,"brand":"The Swaen","name":"Blackswaen Honey Biscuit","country":"NL","lovibond":30,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":420,"brand":"The Swaen","name":"CaraMunich","country":"NL","lovibond":35,"ppg":36.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1883,"brand":"The Swaen","name":"Dutch Pale Ale","country":"NL","lovibond":3.18417,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":1576,"brand":"The Swaen","name":"GoldSwaen Amber","country":"NL","lovibond":27,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1226,"brand":"The Swaen","name":"GoldSwaen Aroma","country":"NL","lovibond":150,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1013,"brand":"The Swaen","name":"Goldswaen Belge","country":"NL","lovibond":14,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1382,"brand":"The Swaen","name":"GoldSwaen Brown","country":"NL","lovibond":82.5,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1580,"brand":"The Swaen","name":"GoldSwaen Brown Light","country":"NL","lovibond":180,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":838,"brand":"The Swaen","name":"GoldSwaen Brown Supreme","country":"NL","lovibond":113,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1969,"brand":"The Swaen","name":"Goldswaen Classic","country":"NL","lovibond":45,"ppg":38,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1156,"brand":"The Swaen","name":"Goldswaen light","country":"NL","lovibond":10,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1318,"brand":"The Swaen","name":"GoldSwaen Munich Dark","country":"NL","lovibond":55,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1468,"brand":"The Swaen","name":"GoldSwaen Munich Light","country":"NL","lovibond":30,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1541,"brand":"The Swaen","name":"GoldSwaen Red","country":"NL","lovibond":19,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1884,"brand":"The Swaen","name":"Greenswaen Pilsner","country":"NL","lovibond":1.9,"ppg":37.3,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1885,"brand":"The Swaen","name":"GreenSwaen Vienna","country":"NL","lovibond":4,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1637,"brand":"The Swaen","name":"Melany","country":"NL","lovibond":27,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"specialty malt"},
  {"id":2179,"brand":"The Swaen","name":"Munich Dark","country":"NL","lovibond":8,"ppg":37,"mashable":1,"category":"Grain","diastatic":48,"graintype":"specialty malt"},
  {"id":2407,"brand":"The Swaen","name":"Munich Light","country":"NL","lovibond":5.5,"ppg":35.3,"mashable":1,"category":"Grain","diastatic":60,"graintype":"specialty malt"},
  {"id":2089,"brand":"The Swaen","name":"PlatinumSwaen Salty Caramel Malt","country":"NL","lovibond":10,"ppg":30,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2248,"brand":"The Swaen","name":"Platinumswaen Sauer","country":"NL","lovibond":5.80728,"ppg":27,"mashable":1,"category":"Grain","diastatic":70,"graintype":"acidulated malt"},
  {"id":1174,"brand":"The Swaen","name":"PlatinumSwaen Smoke","country":"NL","lovibond":2.80943,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":16,"graintype":"smoked malt"},
  {"id":1583,"brand":"The Swaen","name":"PlatinumSwaen Spelt","country":"NL","lovibond":3.6,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1584,"brand":"The Swaen","name":"Swaen Amber","country":"NL","lovibond":20,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1316,"brand":"The Swaen","name":"Swaen Vienna","country":"NL","lovibond":4.5,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":577,"brand":"The Swaen","name":"WhiteSwaen Classic","country":"NL","lovibond":2,"ppg":37.7,"mashable":1,"category":"Grain","diastatic":60,"graintype":"base malt"},
  {"id":394,"brand":"Thomas Fawcett","name":"Amber Malt","country":"GB","lovibond":38,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":20,"graintype":"roasted malt"},
  {"id":531,"brand":"Thomas Fawcett","name":"Dark Crystal Malt II","country":"GB","lovibond":120,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1750,"brand":"Thomas Fawcett","name":"Golden Promise Pale Ale Malt","country":"GB","lovibond":2.65,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1998,"brand":"Thomas Fawcett","name":"Roasted Rye","country":"GB","lovibond":190,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1273,"brand":"Thomas Fawcett","name":"Roasted Wheat","country":"GB","lovibond":325,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":396,"brand":"Thomas Fawcett","name":" Halcyon Pale Ale ","country":"United Kingdom","lovibond":2.7,"ppg":37,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":387,"brand":"Thomas Fawcett","name":"Black Malt","country":"United Kingdom","lovibond":545,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":393,"brand":"Thomas Fawcett","name":"Brown","country":"United Kingdom","lovibond":71,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":579,"brand":"Thomas Fawcett","name":"Caramalt","country":"United Kingdom","lovibond":32,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":409,"brand":"Thomas Fawcett","name":"Chocolate Malt","country":"United Kingdom","lovibond":420,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":486,"brand":"Thomas Fawcett","name":"Crystal Malt","country":"United Kingdom","lovibond":61,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":635,"brand":"Thomas Fawcett","name":"Crystal Malt I","country":"United Kingdom","lovibond":45,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":636,"brand":"Thomas Fawcett","name":"Crystal Malt II","country":"United Kingdom","lovibond":65,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1620,"brand":"Thomas Fawcett","name":"Crystal Rye Malt","country":"United Kingdom","lovibond":75,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":909,"brand":"Thomas Fawcett","name":"English Pearl Pale Ale Malt","country":"United Kingdom","lovibond":2.6,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":1762,"brand":"Thomas Fawcett","name":"Maris Otter Low Colour Malt","country":"United Kingdom","lovibond":1.8,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":848,"brand":"Thomas Fawcett","name":"Maris Otter Pale Ale Malt","country":"United Kingdom","lovibond":2.65,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":392,"brand":"Thomas Fawcett","name":"Mild","country":"United Kingdom","lovibond":3.75,"ppg":37,"mashable":1,"category":"Grain","diastatic":53,"graintype":"base malt"},
  {"id":539,"brand":"Thomas Fawcett","name":"Pale Chocolate Malt","country":"United Kingdom","lovibond":230,"ppg":32.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":729,"brand":"Thomas Fawcett","name":"Pale Crystal Malt","country":"United Kingdom","lovibond":23,"ppg":34,"mashable":1,"category":"Grain","diastatic":110,"graintype":"crystal malt"},
  {"id":1813,"brand":"Thomas Fawcett","name":"Pearl Pale Ale Malt","country":"United Kingdom","lovibond":2.65,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":388,"brand":"Thomas Fawcett","name":"Roasted Barley","country":"United Kingdom","lovibond":545,"ppg":31.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1667,"brand":"Troubador Malting","name":"Serenade","country":"American","lovibond":4.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2283,"brand":"Troubador Malting","name":"Ballad","country":"US","lovibond":7,"ppg":38,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":562,"brand":"Troubador Malting","name":"Blue Ballad","country":"US","lovibond":20,"ppg":38.2,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":558,"brand":"Troubador Malting","name":"Encore 50L","country":"US","lovibond":50,"ppg":22,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":564,"brand":"Troubador Malting","name":"Moonlight Serenade","country":"US","lovibond":350,"ppg":0,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1913,"brand":"Valley Malt","name":"chit malt","country":"American","lovibond":1.4,"ppg":33,"mashable":1,"category":"Grain","diastatic":75,"graintype":"base malt"},
  {"id":1491,"brand":"Viking","name":"Colorado Crystal 10","country":"American","lovibond":10,"ppg":35.42,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2272,"brand":"Viking","name":"Carabody","country":"FI","lovibond":2.65954,"ppg":10,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":622,"brand":"Viking","name":"Caramel 100","country":"FI","lovibond":50.8,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":623,"brand":"Viking","name":"Caramel 150","country":"FI","lovibond":76.2,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":624,"brand":"Viking","name":"Caramel 300","country":"FI","lovibond":152.4,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2464,"brand":"Viking","name":"Caramel 600 (225L)","country":"FI","lovibond":225,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":509,"brand":"Viking","name":"Caramel Aromatic 180EBC (68L)","country":"FI","lovibond":68,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1660,"brand":"Viking","name":"Colorado Honey Malt","country":"FI","lovibond":6,"ppg":35,"mashable":1,"category":"Grain","diastatic":45,"graintype":"roasted malt"},
  {"id":652,"brand":"Viking","name":"Munich","country":"FI","lovibond":9.1,"ppg":36,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":463,"brand":"Viking","name":"Munich Dark Malt","country":"FI","lovibond":8.5,"ppg":33,"mashable":1,"category":"Grain","diastatic":20,"graintype":"specialty malt"},
  {"id":700,"brand":"Viking","name":"Munich Light","country":"FI","lovibond":6,"ppg":36,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1930,"brand":"Viking","name":"Oak Smoked Wheat","country":"FI","lovibond":4.3,"ppg":31,"mashable":1,"category":"Grain","diastatic":120,"graintype":"smoked malt"},
  {"id":2156,"brand":"Viking","name":"Pale Cookie","country":"FI","lovibond":9.8,"ppg":34,"mashable":1,"category":"Grain","diastatic":110,"graintype":"roasted malt"},
  {"id":1190,"brand":"Viking","name":"Red Active Malt","country":"FI","lovibond":14,"ppg":33,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":1263,"brand":"Viking","name":"Rye Malt","country":"FI","lovibond":3.2,"ppg":38,"mashable":1,"category":"Grain","diastatic":90,"graintype":"specialty malt"},
  {"id":1592,"brand":"Viking","name":"Smoked Wheat","country":"FI","lovibond":3,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2101,"brand":"Viking","name":"Black Malt","country":"Finland","lovibond":500,"ppg":21,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":727,"brand":"Viking","name":"Cara Plus 10","country":"Finland","lovibond":5.1,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":650,"brand":"Viking","name":"Cara Plus 150","country":"Finland","lovibond":76.2,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":464,"brand":"Viking","name":"Caramel 200 Malt","country":"Finland","lovibond":75,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":461,"brand":"Viking","name":"Caramel 30 Malt","country":"Finland","lovibond":11.8,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1692,"brand":"Viking","name":"Caramel 50 Malt(20L)","country":"Finland","lovibond":20,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":462,"brand":"Viking","name":"Caramel Pale","country":"Finland","lovibond":3.6,"ppg":35,"mashable":1,"category":"Grain","diastatic":110,"graintype":"crystal malt"},
  {"id":1119,"brand":"Viking","name":"Chocolate Light","country":"Finland","lovibond":150,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1375,"brand":"Viking","name":"Cookie Malt","country":"Finland","lovibond":25.4,"ppg":37,"mashable":0,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2094,"brand":"Viking","name":"Dark Ale Malt","country":"Finland","lovibond":14,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":755,"brand":"Viking","name":"Dextrine Malt","country":"Finland","lovibond":1.8,"ppg":33,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1262,"brand":"Viking","name":"Extra pale malt","country":"Finland","lovibond":1.8,"ppg":37.6,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":648,"brand":"Viking","name":"Golden Ale Malt","country":"Finland","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":16,"graintype":"base malt"},
  {"id":492,"brand":"Viking","name":"Pale Ale Malt","country":"Finland","lovibond":2.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":65,"graintype":"base malt"},
  {"id":410,"brand":"Viking","name":"Pilsner Malt","country":"Finland","lovibond":1.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1409,"brand":"Viking","name":"Pilsner Organic","country":"Finland","lovibond":2,"ppg":37,"mashable":1,"category":"Grain","diastatic":67,"graintype":"base malt"},
  {"id":459,"brand":"Viking","name":"Pilsner Zero Malt","country":"Finland","lovibond":1.9,"ppg":37,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":460,"brand":"Viking","name":"Red Ale Malt","country":"Finland","lovibond":27,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":774,"brand":"Viking","name":"Roasted Barley","country":"Finland","lovibond":340,"ppg":32.7,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1405,"brand":"Viking","name":"Roasted Wheat","country":"Finland","lovibond":550,"ppg":25.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":726,"brand":"Viking","name":"Sahtimallas","country":"Finland","lovibond":2.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":653,"brand":"Viking","name":"Vienna","country":"Finland","lovibond":3.6,"ppg":37,"mashable":1,"category":"Grain","diastatic":50,"graintype":"base malt"},
  {"id":629,"brand":"Viking","name":"Wheat Malt","country":"Finland","lovibond":2.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":766,"brand":"Viking","name":"Roasted Rye","country":"German","lovibond":500,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1394,"brand":"Voyager Craft Malt","name":"Biscuit","country":"AU","lovibond":15.2284,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":27,"graintype":"roasted malt"},
  {"id":1395,"brand":"Voyager Craft Malt","name":"Chocolate","country":"AU","lovibond":406.091,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1398,"brand":"Voyager Craft Malt","name":"Munich Schooner","country":"AU","lovibond":8.62944,"ppg":36,"mashable":1,"category":"Grain","diastatic":44,"graintype":"specialty malt"},
  {"id":1388,"brand":"Voyager Craft Malt","name":"Pale Schooner","country":"AU","lovibond":2.1,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":170,"graintype":"base malt"},
  {"id":1400,"brand":"Voyager Craft Malt","name":"Roast Barley","country":"AU","lovibond":609.137,"ppg":33.1,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1401,"brand":"Voyager Craft Malt","name":"Sm40 Schooner","country":"AU","lovibond":20.3046,"ppg":36.5,"mashable":1,"category":"Grain","diastatic":26,"graintype":"specialty malt"},
  {"id":1166,"brand":"Voyager Craft Malt","name":"Veloria Schooner","country":"AU","lovibond":3.6,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":150,"graintype":"base malt"},
  {"id":1392,"brand":"Voyager Craft Malt","name":"Amber","country":"Australian","lovibond":25.3807,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1393,"brand":"Voyager Craft Malt","name":"Atlas Latrobe","country":"Australian","lovibond":2.03046,"ppg":37.6,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1396,"brand":"Voyager Craft Malt","name":"Heritage Rye","country":"Australian","lovibond":3.04569,"ppg":38.5,"mashable":1,"category":"Grain","diastatic":44,"graintype":"base malt"},
  {"id":1397,"brand":"Voyager Craft Malt","name":"Malted Oats Mitika","country":"Australian","lovibond":3.04569,"ppg":27,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":1399,"brand":"Voyager Craft Malt","name":"Pale Compass","country":"Australian","lovibond":2.79188,"ppg":38.1,"mashable":1,"category":"Grain","diastatic":76,"graintype":"base malt"},
  {"id":1402,"brand":"Voyager Craft Malt","name":"Triticale","country":"Australian","lovibond":3.04569,"ppg":38.5,"mashable":1,"category":"Grain","diastatic":45,"graintype":"base malt"},
  {"id":1403,"brand":"Voyager Craft Malt","name":"Vienna Schooner","country":"Australian","lovibond":6.09137,"ppg":36,"mashable":1,"category":"Grain","diastatic":39,"graintype":"base malt"},
  {"id":1167,"brand":"Voyager Craft Malt","name":"Voodoo Schooner","country":"Australian","lovibond":43.2,"ppg":35.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1404,"brand":"Voyager Craft Malt","name":"Winter Wheat Janz","country":"Australian","lovibond":2.53807,"ppg":38.8,"mashable":1,"category":"Grain","diastatic":64,"graintype":"base malt"},
  {"id":374,"brand":"Weyermann","name":"Barke Munich Malt","country":"DE","lovibond":8,"ppg":37,"mashable":1,"category":"Grain","diastatic":47,"graintype":"specialty malt"},
  {"id":630,"brand":"Weyermann","name":"Beech Smoked Barley","country":"DE","lovibond":2.7,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"smoked malt"},
  {"id":498,"brand":"Weyermann","name":"CaraAroma","country":"DE","lovibond":140,"ppg":36,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1714,"brand":"Weyermann","name":"Carafa II","country":"DE","lovibond":415,"ppg":31.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1773,"brand":"Weyermann","name":"Carafa Special Type II","country":"DE","lovibond":425,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":671,"brand":"Weyermann","name":"Carapils","country":"DE","lovibond":2.1,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1797,"brand":"Weyermann","name":"CaraRed","country":"DE","lovibond":19.3,"ppg":35,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1557,"brand":"Weyermann","name":"CaraWheat","country":"DE","lovibond":45,"ppg":31,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":2312,"brand":"Weyermann","name":"Diastatic Barley","country":"DE","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":2324,"brand":"Weyermann","name":"Floor-Malted Bohemian Pilsner","country":"DE","lovibond":1.9,"ppg":36.3,"mashable":1,"category":"Grain","diastatic":120,"graintype":"base malt"},
  {"id":2310,"brand":"Weyermann","name":"Melanoidin","country":"DE","lovibond":27,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":449,"brand":"Weyermann","name":"Munich Type I","country":"DE","lovibond":6,"ppg":38,"mashable":1,"category":"Grain","diastatic":70,"graintype":"specialty malt"},
  {"id":1545,"brand":"Weyermann","name":"Munich Type II (Dark)","country":"DE","lovibond":10,"ppg":37,"mashable":1,"category":"Grain","diastatic":37,"graintype":"specialty malt"},
  {"id":775,"brand":"Weyermann","name":"Oak Smoked Wheat Malt","country":"DE","lovibond":2,"ppg":38,"mashable":1,"category":"Grain","diastatic":62,"graintype":"smoked malt"},
  {"id":467,"brand":"Weyermann","name":"Abbey Malt","country":"German","lovibond":17,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"base malt"},
  {"id":647,"brand":"Weyermann","name":"Acidulated","country":"German","lovibond":3.4,"ppg":27,"mashable":1,"category":"Grain","diastatic":0,"graintype":"acidulated malt"},
  {"id":763,"brand":"Weyermann","name":"Barke Pilsner Malt","country":"German","lovibond":1.75,"ppg":37.03,"mashable":1,"category":"Grain","diastatic":125,"graintype":"base malt"},
  {"id":1129,"brand":"Weyermann","name":"Barke Vienna","country":"German","lovibond":3.4,"ppg":36,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":608,"brand":"Weyermann","name":"BIOLAND Pilsner Malt (Organic)","country":"German","lovibond":2.8,"ppg":29,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":880,"brand":"Weyermann","name":"Bohemian Pilsner Malt","country":"German","lovibond":2.43,"ppg":36.8,"mashable":1,"category":"Grain","diastatic":36,"graintype":"base malt"},
  {"id":447,"brand":"Weyermann","name":"CaraAmber","country":"German","lovibond":27,"ppg":37,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":84,"brand":"Weyermann","name":"CARABELGE","country":"German","lovibond":13.6,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":522,"brand":"Weyermann","name":"Carafa I","country":"German","lovibond":340,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":541,"brand":"Weyermann","name":"Carafa III","country":"German","lovibond":525,"ppg":32,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":530,"brand":"Weyermann","name":"Carafa Special Type 1 ","country":"German","lovibond":340,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":698,"brand":"Weyermann","name":"Carafa Special Type III","country":"German","lovibond":525,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1725,"brand":"Weyermann","name":"Carafoam","country":"German","lovibond":2.2,"ppg":34.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":446,"brand":"Weyermann","name":"Carahell","country":"German","lovibond":10,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":1421,"brand":"Weyermann","name":"Caramunich Type 1","country":"German","lovibond":35,"ppg":33.5,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":489,"brand":"Weyermann","name":"Caramunich Type 2","country":"German","lovibond":45,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":576,"brand":"Weyermann","name":"Caramunich Type 3","country":"German","lovibond":57,"ppg":34,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":887,"brand":"Weyermann","name":"Chocolate Rye","country":"German","lovibond":240,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":1527,"brand":"Weyermann","name":"Chocolate wheat","country":"German","lovibond":400,"ppg":0,"mashable":1,"category":"Grain","diastatic":35,"graintype":"roasted malt"},
  {"id":1636,"brand":"Weyermann","name":"Chocolate Wheat","country":"German","lovibond":415,"ppg":34.8,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":612,"brand":"Weyermann","name":"Cologne Malt","country":"German","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":80,"graintype":"base malt"},
  {"id":721,"brand":"Weyermann","name":"Dark Wheat Malt","country":"German","lovibond":7,"ppg":37,"mashable":1,"category":"Grain","diastatic":120,"graintype":"crystal malt"},
  {"id":405,"brand":"Weyermann","name":"Extra Pale Premium Pilsner Malt","country":"German","lovibond":1.5,"ppg":38,"mashable":1,"category":"Grain","diastatic":110,"graintype":"base malt"},
  {"id":524,"brand":"Weyermann","name":"Pale Ale","country":"German","lovibond":2.3,"ppg":39,"mashable":1,"category":"Grain","diastatic":70,"graintype":"base malt"},
  {"id":702,"brand":"Weyermann","name":"Pale Rye","country":"German","lovibond":3.2,"ppg":37,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":527,"brand":"Weyermann","name":"Pale Wheat","country":"German","lovibond":2,"ppg":36,"mashable":1,"category":"Grain","diastatic":90,"graintype":"base malt"},
  {"id":552,"brand":"Weyermann","name":"Pilsner","country":"German","lovibond":1.5,"ppg":36,"mashable":1,"category":"Grain","diastatic":100,"graintype":"base malt"},
  {"id":1320,"brand":"Weyermann","name":"Roasted Barley","country":"German","lovibond":432,"ppg":29.9,"mashable":1,"category":"Grain","diastatic":0,"graintype":"roasted malt"},
  {"id":2161,"brand":"Weyermann","name":"Rye Malt","country":"German","lovibond":3.7,"ppg":36,"mashable":1,"category":"Grain","diastatic":105,"graintype":"base malt"},
  {"id":1055,"brand":"Weyermann","name":"Schill Cologne","country":"German","lovibond":2.4,"ppg":37,"mashable":1,"category":"Grain","diastatic":62,"graintype":"base malt"},
  {"id":473,"brand":"Weyermann","name":"Special W","country":"German","lovibond":115,"ppg":33.6,"mashable":1,"category":"Grain","diastatic":0,"graintype":"crystal malt"},
  {"id":730,"brand":"Weyermann","name":"Vienna Malt","country":"German","lovibond":3.5,"ppg":37,"mashable":1,"category":"Grain","diastatic":93,"graintype":"base malt"},
  {"id":158,"brand":"","name":"Liquid Malt Extract - Amber","country":"","lovibond":10,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":159,"brand":"","name":"Liquid Malt Extract - Dark","country":"","lovibond":30,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":190,"brand":"","name":"Liquid Malt Extract - Extra Light","country":"","lovibond":2.5,"ppg":37,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":160,"brand":"","name":"Liquid Malt Extract - Light","country":"","lovibond":4,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":185,"brand":"","name":"Liquid Malt Extract - Munich","country":"","lovibond":8,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":186,"brand":"","name":"Liquid Malt Extract - Pilsen","country":"","lovibond":2,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":161,"brand":"","name":"Liquid Malt Extract - Wheat","country":"","lovibond":3,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":1277,"brand":"","name":"Maris Otter Liquid Malt Extract (LME)","country":"United Kingdom","lovibond":5,"ppg":36,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":796,"brand":"Briess","name":"CBW Rye","country":"American","lovibond":9,"ppg":34.5,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":939,"brand":"Briess","name":"LME Bavarian Wheat","country":"US","lovibond":3,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":940,"brand":"Briess","name":"LME Golden Light","country":"US","lovibond":4,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":941,"brand":"Briess","name":"LME Goldpils Vienna","country":"US","lovibond":6,"ppg":34.5,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":942,"brand":"Briess","name":"LME Munich","country":"US","lovibond":8,"ppg":34.5,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":943,"brand":"Briess","name":"LME Organic Maltoferm","country":"US","lovibond":4,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":944,"brand":"Briess","name":"LME Pale Ale","country":"US","lovibond":6,"ppg":34.5,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":945,"brand":"Briess","name":"LME Pilsen Light","country":"US","lovibond":2,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":946,"brand":"Briess","name":"LME Porter","country":"US","lovibond":17,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":947,"brand":"Briess","name":"LME Rye","country":"US","lovibond":9,"ppg":34.5,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":948,"brand":"Briess","name":"LME Sparkling Amber Liquid","country":"US","lovibond":10,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":949,"brand":"Briess","name":"LME Traditional Dark","country":"US","lovibond":30,"ppg":37.6,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":950,"brand":"Briess","name":"LME White Grain Sorghum","country":"US","lovibond":3,"ppg":34.5,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":1857,"brand":"Coopers","name":"Brew Enhancer 3","country":"AU","lovibond":4,"ppg":12,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":1921,"brand":"Coopers","name":"Lager kit","country":"AU","lovibond":4,"ppg":35,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":1919,"brand":"Coopers","name":"Brew Enhancer 2","country":"Australian","lovibond":6,"ppg":45,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":1856,"brand":"Coopers","name":"IPA Kit","country":"Australian","lovibond":1,"ppg":12,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":1445,"brand":"Weyermann","name":"Sinamar","country":"German","lovibond":3110.82,"ppg":24.4,"mashable":0,"category":"Liquid Extract","diastatic":0,"graintype":"extract"},
  {"id":14,"brand":"","name":"Dry Malt Extract - Amber","country":"","lovibond":10,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":15,"brand":"","name":"Dry Malt Extract - Dark","country":"","lovibond":30,"ppg":44,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":189,"brand":"","name":"Dry Malt Extract - Extra Light","country":"","lovibond":2.5,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":13,"brand":"","name":"Dry Malt Extract - Light","country":"","lovibond":4,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":187,"brand":"","name":"Dry Malt Extract - Munich","country":"","lovibond":8,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":188,"brand":"","name":"Dry Malt Extract - Pilsen","country":"","lovibond":2,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":16,"brand":"","name":"Dry Malt Extract - Wheat","country":"","lovibond":3,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":377,"brand":"Brewferm","name":"Spraymalt - Light","country":"Belgian","lovibond":2.56,"ppg":0,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":690,"brand":"Briess","name":"CBW\u00ae Golden Light ","country":"American","lovibond":4,"ppg":41,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":930,"brand":"Briess","name":"DME Bavarian Wheat","country":"US","lovibond":3,"ppg":44.6,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":931,"brand":"Briess","name":"DME Golden Light","country":"US","lovibond":4,"ppg":44.6,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":932,"brand":"Briess","name":"DME Goldpils Vienna","country":"US","lovibond":6,"ppg":45,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":933,"brand":"Briess","name":"DME Munich","country":"US","lovibond":8,"ppg":45,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":934,"brand":"Briess","name":"DME Organic Maltoferm","country":"US","lovibond":4,"ppg":45,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":935,"brand":"Briess","name":"DME Pale Ale","country":"US","lovibond":6,"ppg":45,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":936,"brand":"Briess","name":"DME Pilsen Light","country":"US","lovibond":2,"ppg":45,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":937,"brand":"Briess","name":"DME Sparkling Amber","country":"US","lovibond":10,"ppg":44.6,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":938,"brand":"Briess","name":"DME Traditional Dark","country":"US","lovibond":30,"ppg":44.6,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":1933,"brand":"Muntons","name":"Dry Malt Extract - Light","country":"United Kingdom","lovibond":4.3,"ppg":42,"mashable":0,"category":"Dry Extract","diastatic":0,"graintype":"extract"},
  {"id":2,"brand":"","name":"Flaked Barley","country":"","lovibond":2.2,"ppg":32,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":3,"brand":"","name":"Flaked Corn","country":"","lovibond":0.5,"ppg":40,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":4,"brand":"","name":"Flaked Oats","country":"","lovibond":2.2,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":5,"brand":"","name":"Flaked Rice","country":"","lovibond":0.5,"ppg":40,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":6,"brand":"","name":"Flaked Rye","country":"","lovibond":2.8,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":7,"brand":"","name":"Flaked Wheat","country":"","lovibond":2,"ppg":34,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":8,"brand":"","name":"Grits","country":"","lovibond":1,"ppg":37,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2546,"brand":"","name":"Oat hulls","country":"","lovibond":0,"ppg":0,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"other"},
  {"id":1959,"brand":"","name":"Rice Hulls","country":"","lovibond":0,"ppg":0,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"other"},
  {"id":10,"brand":"","name":"Rolled Oats","country":"","lovibond":2.2,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":11,"brand":"","name":"Torrified Barley","country":"","lovibond":2,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":12,"brand":"","name":"Torrified Wheat","country":"","lovibond":2,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":71,"brand":"","name":"Unmalted Wheat","country":"","lovibond":2,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":326,"brand":"","name":"Rolled Barley","country":"New Zealand","lovibond":1.7,"ppg":35.4,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":327,"brand":"","name":"Rolled Oats","country":"New Zealand","lovibond":1.4,"ppg":12.4,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1254,"brand":"BA Malt","name":"Avena","country":"Argentina","lovibond":2.2,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"other"},
  {"id":669,"brand":"Bays Best Feed","name":"American - Oats","country":"US","lovibond":10,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":821,"brand":"Bob's Red MIll","name":"Corn Grits","country":"","lovibond":1,"ppg":15,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1711,"brand":"Bob's Red MIll","name":"Flaked Oats","country":"","lovibond":1,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":954,"brand":"Briess","name":"Barley, Flaked","country":"US","lovibond":1.4,"ppg":32.2,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":959,"brand":"Briess","name":"Brewers Brown Rice Flakes","country":"US","lovibond":1,"ppg":27.6,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":961,"brand":"Briess","name":"Brewers Oat Flakes","country":"US","lovibond":2.5,"ppg":32.2,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":962,"brand":"Briess","name":"Brewers Red Wheat Flakes","country":"US","lovibond":2,"ppg":32.2,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":963,"brand":"Briess","name":"Brewers Rye Flakes","country":"US","lovibond":3,"ppg":32.7,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":964,"brand":"Briess","name":"Brewers Torrified Wheat","country":"US","lovibond":1.5,"ppg":35,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1793,"brand":"Briess","name":"Brewers Triticale Flakes","country":"US","lovibond":2.5,"ppg":32.7,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":965,"brand":"Briess","name":"Brewers Yellow Corn Flakes","country":"US","lovibond":0.8,"ppg":34.5,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1000,"brand":"Briess","name":"Rice, Flaked","country":"US","lovibond":1,"ppg":32.2,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":355,"brand":"BSG","name":"Flaked White Wheat","country":"US","lovibond":1.6,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":417,"brand":"Crisp Malting","name":"Flaked Torrefied Maize","country":"GB","lovibond":0.66,"ppg":36.8,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2632,"brand":"Crisp Malting","name":"Flaked Torrefied Rice","country":"GB","lovibond":0.5,"ppg":37,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"other"},
  {"id":654,"brand":"Crisp Malting","name":"Flaked Torrified Oats","country":"GB","lovibond":3.2,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1691,"brand":"Crisp Malting","name":"Torrified Wheat","country":"GB","lovibond":2,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1908,"brand":"Dingemans","name":"Dingemans De Husked Roasted Barley (525\u00b0L)","country":"BE","lovibond":525,"ppg":30,"mashable":1,"category":"Adjunct","diastatic":70,"graintype":"roasted malt"},
  {"id":2052,"brand":"Gladfield","name":"Flaked Spelt","country":"NZ","lovibond":1.7,"ppg":32.2,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2018,"brand":"Gladfield","name":"Harraway's Rolled Oats","country":"NZ","lovibond":1.4,"ppg":39.1,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2017,"brand":"Gladfield","name":"Harraways Flaked Wheat","country":"NZ","lovibond":1.6,"ppg":35.4,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2016,"brand":"Gladfield","name":"Harraways Rolled Barley","country":"NZ","lovibond":1.7,"ppg":35.4,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2014,"brand":"Gladfield","name":"Raw Barley","country":"NZ","lovibond":2,"ppg":28,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2013,"brand":"Gladfield","name":"Raw Wheat","country":"NZ","lovibond":2,"ppg":28,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":2077,"brand":"Grain Millers","name":"Rolled Oats","country":"American","lovibond":2.2,"ppg":33,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1901,"brand":"Grain Millers","name":"Flaked Oats","country":"US","lovibond":1.5,"ppg":34.6,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1307,"brand":"Grain Millers","name":"Flaked White Wheat","country":"US","lovibond":1.6,"ppg":36,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":1658,"brand":"Grouse Malt House","name":"Flaked Quinoa","country":"US","lovibond":1,"ppg":1,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":378,"brand":"Holland & Barrett","name":"Organic Flaked Barley","country":"GB","lovibond":2.52,"ppg":28,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":382,"brand":"Joe White","name":"Raw Wheat","country":"AU","lovibond":1.5,"ppg":20,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":902,"brand":"Rahr","name":"Unmalted Wheat","country":"US","lovibond":2.5,"ppg":34.5,"mashable":1,"category":"Adjunct","diastatic":0,"graintype":"raw"},
  {"id":249,"brand":"","name":"Acerola","country":"","lovibond":0,"ppg":1.5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":250,"brand":"","name":"Apple","country":"","lovibond":0,"ppg":5.6,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":223,"brand":"","name":"Apricot","country":"","lovibond":0,"ppg":4.05,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":251,"brand":"","name":"Apricot (dried)","country":"","lovibond":0,"ppg":17.9,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":224,"brand":"","name":"Banana","country":"","lovibond":0,"ppg":7.65,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":252,"brand":"","name":"Blackberry","country":"","lovibond":0,"ppg":3.6,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":225,"brand":"","name":"Blueberry","country":"","lovibond":0,"ppg":4.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":253,"brand":"","name":"Boysenberry","country":"","lovibond":0,"ppg":2.5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":254,"brand":"","name":"Cantaloupe","country":"","lovibond":0,"ppg":3.4,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":255,"brand":"","name":"Carambola","country":"","lovibond":0,"ppg":1.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":256,"brand":"","name":"Carrot","country":"","lovibond":0,"ppg":2,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":257,"brand":"","name":"Casaba Melon","country":"","lovibond":0,"ppg":1.9,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":258,"brand":"","name":"Cashew","country":"","lovibond":0,"ppg":3,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":259,"brand":"","name":"Celery","country":"","lovibond":0,"ppg":0.75,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":226,"brand":"","name":"Cherry","country":"","lovibond":0,"ppg":6.3,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":260,"brand":"","name":"Cherry, dark sweet","country":"","lovibond":0,"ppg":6.4,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":261,"brand":"","name":"Cherry, Montmorency","country":"","lovibond":0,"ppg":3.55,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":262,"brand":"","name":"Crabapple","country":"","lovibond":0,"ppg":3.85,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":227,"brand":"","name":"Cranberry","country":"","lovibond":0,"ppg":1.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":263,"brand":"","name":"Currant, black","country":"","lovibond":0,"ppg":4.2,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":264,"brand":"","name":"Currant, red","country":"","lovibond":0,"ppg":2.7,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":228,"brand":"","name":"Date","country":"","lovibond":0,"ppg":27,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":265,"brand":"","name":"Date (dried)","country":"","lovibond":0,"ppg":28.9,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":266,"brand":"","name":"Dewberry","country":"","lovibond":0,"ppg":2.5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":267,"brand":"","name":"Elderberry","country":"","lovibond":0,"ppg":2.75,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":268,"brand":"","name":"Fig","country":"","lovibond":0,"ppg":5.3,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":269,"brand":"","name":"Figs (dried)","country":"","lovibond":0,"ppg":29.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":229,"brand":"","name":"Gooseberry","country":"","lovibond":0,"ppg":4.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":271,"brand":"","name":"Grape","country":"","lovibond":0,"ppg":7.15,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":270,"brand":"","name":"Grape, concord","country":"","lovibond":0,"ppg":4,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":272,"brand":"","name":"Grapefruit","country":"","lovibond":0,"ppg":2.75,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":273,"brand":"","name":"Guanabana","country":"","lovibond":0,"ppg":4,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":274,"brand":"","name":"Guava","country":"","lovibond":0,"ppg":2.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":275,"brand":"","name":"Honeydew Melon","country":"","lovibond":0,"ppg":4.5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":276,"brand":"","name":"Jackfruit","country":"","lovibond":0,"ppg":8.3,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":277,"brand":"","name":"Kiwi","country":"","lovibond":0,"ppg":5.75,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":278,"brand":"","name":"Lemon juice","country":"","lovibond":0,"ppg":1,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"juice"},
  {"id":230,"brand":"","name":"Lime","country":"","lovibond":0,"ppg":0.45,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":279,"brand":"","name":"Lime juice","country":"","lovibond":0,"ppg":0.45,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"puree"},
  {"id":281,"brand":"","name":"Loganberry","country":"","lovibond":0,"ppg":2.6,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":280,"brand":"","name":"Lychee (Litchi)","country":"","lovibond":0,"ppg":7.65,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":231,"brand":"","name":"Mango","country":"","lovibond":0,"ppg":4.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":283,"brand":"","name":"Maple Sap","country":"","lovibond":0,"ppg":0.9,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":282,"brand":"","name":"Maple Syrup","country":"","lovibond":0,"ppg":29.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":284,"brand":"","name":"Mulberry","country":"","lovibond":0,"ppg":6.1,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":285,"brand":"","name":"Nectarine","country":"","lovibond":0,"ppg":3.4,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":303,"brand":"","name":"Orange","country":"","lovibond":0,"ppg":4.1,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":304,"brand":"","name":"Orange (Blood Orange)","country":"","lovibond":0,"ppg":4.05,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":286,"brand":"","name":"Orange juice","country":"","lovibond":0,"ppg":4.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"juice"},
  {"id":287,"brand":"","name":"Papaya","country":"","lovibond":0,"ppg":2.9,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":288,"brand":"","name":"Passionfruit","country":"","lovibond":0,"ppg":5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":232,"brand":"","name":"Peach","country":"","lovibond":0,"ppg":4.05,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":233,"brand":"","name":"Pear","country":"","lovibond":0,"ppg":4.5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":289,"brand":"","name":"Persimmon","country":"","lovibond":0,"ppg":6.3,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":234,"brand":"","name":"Pineapple","country":"","lovibond":0,"ppg":5.85,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":235,"brand":"","name":"Plum","country":"","lovibond":0,"ppg":4.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":290,"brand":"","name":"Pomegranate","country":"","lovibond":0,"ppg":5.2,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":291,"brand":"","name":"Prickly Pear","country":"","lovibond":0,"ppg":4.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":292,"brand":"","name":"Prunes (dried)","country":"","lovibond":0,"ppg":19.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":335,"brand":"","name":"Pumpkin (canned)","country":"","lovibond":0,"ppg":1,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":334,"brand":"","name":"Pumpkin (fresh)","country":"","lovibond":0,"ppg":1,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":293,"brand":"","name":"Quince","country":"","lovibond":0,"ppg":0.45,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":294,"brand":"","name":"Raisins (dried)","country":"","lovibond":0,"ppg":29.25,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":236,"brand":"","name":"Raspberry","country":"","lovibond":0,"ppg":3.15,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":295,"brand":"","name":"Raspberry, black","country":"","lovibond":0,"ppg":2.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":296,"brand":"","name":"Raspberry, red","country":"","lovibond":0,"ppg":2.3,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":297,"brand":"","name":"Rhubarb","country":"","lovibond":0,"ppg":0.4,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":237,"brand":"","name":"Strawberry","country":"","lovibond":0,"ppg":3.15,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":298,"brand":"","name":"Sultanas","country":"","lovibond":0,"ppg":8.55,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":300,"brand":"","name":"Tangelo","country":"","lovibond":0,"ppg":3.35,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":299,"brand":"","name":"Tangerine","country":"","lovibond":0,"ppg":2.95,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":301,"brand":"","name":"Tomato","country":"","lovibond":0,"ppg":1.25,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":238,"brand":"","name":"Watermelon","country":"","lovibond":0,"ppg":4.05,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":302,"brand":"","name":"Youngberry","country":"","lovibond":0,"ppg":2.5,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"fruit"},
  {"id":687,"brand":"","name":"Apple juice","country":"US","lovibond":1,"ppg":5.8,"mashable":0,"category":"Fruit","diastatic":0,"graintype":"juice"},
  {"id":337,"brand":"","name":"Agave Nectar","country":"","lovibond":2,"ppg":35,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":163,"brand":"","name":"Belgian Candi Sugar - Amber/Brown (60L)","country":"","lovibond":60,"ppg":38,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":164,"brand":"","name":"Belgian Candi Sugar - Clear/Blond (0L)","country":"","lovibond":0,"ppg":38,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":165,"brand":"","name":"Belgian Candi Sugar - Dark (275L)","country":"","lovibond":275,"ppg":38,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":170,"brand":"","name":"Brown Rice Syrup - Gluten Free","country":"","lovibond":2,"ppg":44,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":171,"brand":"","name":"Brown Sugar","country":"","lovibond":15,"ppg":45,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":172,"brand":"","name":"Cane Sugar","country":"","lovibond":0,"ppg":46,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":173,"brand":"","name":"Corn Sugar - Dextrose","country":"","lovibond":0.5,"ppg":42,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":174,"brand":"","name":"Corn Syrup","country":"","lovibond":0.5,"ppg":37,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":175,"brand":"","name":"Honey","country":"","lovibond":2,"ppg":35,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":176,"brand":"","name":"Honey (Buckwheat)","country":"","lovibond":2,"ppg":35,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":177,"brand":"","name":"Invert Sugar Syrup","country":"","lovibond":1,"ppg":36,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":179,"brand":"","name":"Lactose (Milk Sugar)","country":"","lovibond":1,"ppg":41,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":191,"brand":"","name":"Maltodextrin","country":"","lovibond":0,"ppg":39,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":178,"brand":"","name":"Maple Syrup","country":"","lovibond":35,"ppg":30,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":180,"brand":"","name":"Molasses","country":"","lovibond":80,"ppg":36,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":1709,"brand":"","name":"Raw Honey","country":"","lovibond":20,"ppg":68.5,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":162,"brand":"","name":"Rice Syrup Solids","country":"","lovibond":1,"ppg":37,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":181,"brand":"","name":"Soft Candi Sugar - Blond","country":"","lovibond":5,"ppg":38,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":182,"brand":"","name":"Soft Candi Sugar - Brown","country":"","lovibond":60,"ppg":38,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":184,"brand":"","name":"Turbinado","country":"","lovibond":10,"ppg":44,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":183,"brand":"","name":"White Sorghum Syrup  - Gluten Free","country":"","lovibond":1.5,"ppg":37,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":2004,"brand":"","name":"Blueberry honey","country":"US","lovibond":2,"ppg":35,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":1478,"brand":"","name":"Brewers Crystals","country":"US","lovibond":2,"ppg":44,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":1710,"brand":"","name":"Cranberry Blossom Honey","country":"US","lovibond":5,"ppg":63,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":2297,"brand":"","name":"Honey - Sweet Clover","country":"US","lovibond":0,"ppg":35,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":1708,"brand":"","name":"Kallas Raw Honey","country":"US","lovibond":0,"ppg":68.5,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":643,"brand":"Bob's Red MIll","name":"Organic Coconut Sugar","country":"American","lovibond":15,"ppg":45,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":1765,"brand":"Brewmaster","name":"Honey, Orange Blossom","country":"","lovibond":1,"ppg":34.8,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":1008,"brand":"Briess","name":"Brewers Corn Syrup","country":"US","lovibond":1,"ppg":40.9,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":1009,"brand":"Briess","name":"Dextrose","country":"US","lovibond":1,"ppg":45.5,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":166,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Amber (40L)","country":"","lovibond":40,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":348,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Blanc","country":"","lovibond":0,"ppg":42,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":346,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Brun Fonc\u00e9","country":"","lovibond":22,"ppg":42,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":347,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Brun L\u00e9ger","country":"","lovibond":6.5,"ppg":42,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":167,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Clear (0L)","country":"","lovibond":0,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":248,"brand":"Candi Syrup","name":"Belgian Candi Syrup - D-180","country":"","lovibond":180,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":340,"brand":"Candi Syrup","name":"Belgian Candi Syrup - D-240","country":"","lovibond":240,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":246,"brand":"Candi Syrup","name":"Belgian Candi Syrup - D-45","country":"","lovibond":45,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":247,"brand":"Candi Syrup","name":"Belgian Candi Syrup - D-90","country":"","lovibond":90,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":168,"brand":"Candi Syrup","name":"Belgian Candi Syrup - D2 (160L)","country":"","lovibond":160,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":169,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Dark (80L)","country":"","lovibond":80,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":336,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Golden (5L)","country":"","lovibond":5,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":345,"brand":"Candi Syrup","name":"Belgian Candi Syrup - Simplicity","country":"","lovibond":1,"ppg":32,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":644,"brand":"Candi Syrup","name":"Cascade Beer Candi Syrup - Thai Ginger","country":"American","lovibond":6.5,"ppg":1.032,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":1922,"brand":"Coopers","name":"Brew Enhancer 1","country":"Australian","lovibond":1,"ppg":45,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":780,"brand":"Franco-Belges","name":"Belgian Candi Sugar - Dark (275L)","country":"BE","lovibond":159.822,"ppg":38,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":2633,"brand":"Gladfield","name":"Dextrose Sugar","country":"New Zealand","lovibond":0,"ppg":46,"mashable":0,"category":"Sugar","diastatic":59,"graintype":"sugar"},
  {"id":2634,"brand":"Gladfield","name":"Lactose (fonterra \"surestart\" Brand)","country":"New Zealand","lovibond":1.01523,"ppg":40.9,"mashable":0,"category":"Sugar","diastatic":59,"graintype":"sugar"},
  {"id":2635,"brand":"Gladfield","name":"Lactose (saputo Brand)","country":"New Zealand","lovibond":1.01523,"ppg":40.9,"mashable":0,"category":"Sugar","diastatic":59,"graintype":"sugar"},
  {"id":2053,"brand":"Gladfield","name":"Dextrose","country":"NZ","lovibond":0,"ppg":46,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":2051,"brand":"Gladfield","name":"Fonterra - Lactose","country":"NZ","lovibond":0,"ppg":35,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":2015,"brand":"Gladfield","name":"Maltodextrin","country":"NZ","lovibond":3,"ppg":34.5,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":395,"brand":"Meridian","name":"Blackstrap","country":"United Kingdom","lovibond":1125,"ppg":40,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
  {"id":1069,"brand":"Northern Brewer","name":"Wildflower Honey","country":"US","lovibond":2,"ppg":42,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"honey"},
  {"id":380,"brand":"Tate and Lyle","name":"Golden Syrup","country":"United Kingdom","lovibond":1,"ppg":40,"mashable":0,"category":"Sugar","diastatic":0,"graintype":"sugar"},
];

const FERMENTABLES = UNSORTED_FERMENTABLES.sort(function(a, b) {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
});

export const FERMENTABLE_MAP = FERMENTABLES.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {});

export default FERMENTABLES;
