import Calculators from '../Calculators';
import FermentableSelector from '../components/FermentableSelector';

const FermentableListItem = (props) => {
  const { pounds, fermentable_id, uuid } = props.ingredient;
  const { percentOfBill } = props;

  const handlePoundsChange = (e) => {
    const poundsFloat = Calculators.strToFloat(e.target.value);
    props.onIngredientChange(uuid, { pounds: poundsFloat });
  }

  const handleFermentableChange = fermentable => {
    props.onIngredientChange(uuid, { fermentable_id: fermentable.id });
  }

  const removeIngredient = () => {
    props.onIngredientRemoved(uuid);
  }

  return (
    <li>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="md:grid md:grid-cols-9 md:gap-4 flex-1">
          <div className="col-span-2 mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              name="weight"
              id="weight"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              defaultValue={pounds}
              onChange={handlePoundsChange}
            />

            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                Lbs
              </span>
            </div>
          </div>

          <div className="col-span-4">
            <FermentableSelector
              onChange={handleFermentableChange}
            />
          </div>

          <div className="col-span-1 flex items-center justify-center content-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {percentOfBill}%
            </h3>
          </div>

          <div className="col-span-2 flex flex-wrap content-center justify-end">
            <button
              type="button"
              onClick={removeIngredient}
            >
              <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

export default FermentableListItem;
