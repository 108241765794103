import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Calculators from '../Calculators';
import FermentableListItem from '../components/FermentableListItem';

const newIngredient = () => {
  return {
    uuid: uuidv4(),
    fermentable_id: 47,
    pounds: 10.0
  }
}

class OriginalGravityCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gallons: 5.0,
      efficiency: 75,
      ingredients: [
        newIngredient()
      ]
    };
  }

  gallonsChanged = (event) => {
    this.setState({
      gallons: Calculators.strToFloat(event.target.value)
    });
  }

  efficiencyChanged = (event) => {
    this.setState({
      efficiency: Calculators.strToFloat(event.target.value)
    });
  }

  get efficiency() {
    return this.state.efficiency / 100;
  }

  get originalGravity() {
    return Calculators.originalGravity(
      Calculators.totalPointsFromIngredients(this.state.ingredients),
      this.state.gallons,
      this.efficiency
    );
  }

  get percentOfBill() {
    return Calculators.round(this.state.pounds / this.totalWeight * 100, 2);
  }

  addIngredient = () => {
    this.setState({
      ingredients: [
        ...this.state.ingredients,
        newIngredient()
      ]
    });
  }

  ingredientChanged = (uuid, newAttrs) => {
    this.setState({
      ingredients: this.state.ingredients.map(ingredient => ingredient.uuid === uuid
        ? { ...ingredient, ...newAttrs }
        : ingredient
      )
    });
  }

  ingredientRemoved = (uuid) => {
    this.setState({
      ingredients: this.state.ingredients.filter(ingredient => uuid !== ingredient.uuid)
    });
  }

  get totalWeight() {
    return this.state.ingredients.reduce((acc, ingredient) => acc + ingredient.pounds, 0);
  }

  render() {
    const totalWeight = this.state.ingredients.reduce((acc, ingredient) => acc + ingredient.pounds, 0);

    const percentOfBill = (pounds) => {
      return Calculators.round(pounds / totalWeight * 100, 2);
    }

    const fermentableListItems = this.state.ingredients.map((ingredient) =>
      <FermentableListItem
        key={ingredient.uuid}
        ingredient={ingredient}
        percentOfBill={percentOfBill(ingredient.pounds)}
        onIngredientChange={this.ingredientChanged}
        onIngredientRemoved={this.ingredientRemoved}
      />
    );

    return (
      <div>
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Original Gravity Calculator
            </h1>
          </div>
        </header>

        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <h2 className="text-xl font-bold">
                        Original Gravity:
                        <span className="ml-2 text-3xl font-bold">{this.originalGravity}</span>
                      </h2>
                    </div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="gallons" className="block text-sm font-medium text-gray-700">
                        Gallons
                      </label>

                      <input
                        type="text"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        name="gallons"
                        id="gallons"
                        placeholder="Gallons"
                        onChange={this.gallonsChanged}
                        value={this.state.gallons}
                        required
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="efficiency" className="block text-sm font-medium text-gray-700">
                        Efficiency
                      </label>

                      <div className="col-span-2 mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                          id="efficiency"
                          placeholder="Efficiency"
                          onChange={this.efficiencyChanged}
                          value={this.state.efficiency}
                          required
                        />

                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="max-w-none mx-auto">
            <div className="bg-white sm:rounded-lg sm:shadow">

              <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Fermentables
                    </h3>

                    <p className="mt-1 text-sm text-gray-500">
                      Each fermentable has a potential points per gallon (PPG) which contributes to the total fermentable sugars.
                    </p>
                  </div>
                  <div className="ml-4 mt-2 flex-shrink-0">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={this.addIngredient}
                    >
                      Add Ingredient
                    </button>
                  </div>
                </div>
              </div>

              <ul className="divide-y divide-gray-200">
                {fermentableListItems}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OriginalGravityCalculator;
