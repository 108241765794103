import Fermentable from './models/Fermentable';

//The 2-row has a potential of 1.036, so a single pound of 2-row contains 36 gravity points. There is a total of 10 lbs, so 2-row will contribute a total of 360 point to the beer.
//Crystal 20 and 40 have a specific gravity of 1.034. Crystal 20 will contribute 17 gravity points and crystal 40 will contribute 8.5 gravity points.
//The total number of points for the grain bill is just the sum of all the gravity points.
//The example grain bill will have 385.5 gravity points.
//For a list of common base and specialty malts and their specific gravities,
//click here (http://www.homebrewtalk.com/wiki/index.php/Malts_Chart).

//The total number of points calculated in the previous section assumes you were able to extract all 100% of the
//sugars out of the grain. Extracting 100% of the sugars out of the grain is impossible. However, all-grain homebrew systems can extract up to 90% of the sugar out of grain.
//This percentage is called the mash efficiency. The mash efficiency is different for every brewer and equipment setup.
//It's recommend to aim for anywhere between 70-80%. For the example grain bill above, lets assume the mash efficiency is 75%. So we take the total number of gravity points and multiply it by 0.75. So the total number of gravity points for this grain bill is 289.9 gravity points. The only way to know the mash efficiency for a particular setup is by brewing on the setup, calculating the mash efficiency, and becoming familiar with your setup. Experience will allow you to pin-down your mash efficiency. A mash efficiency of 75% is a good starting place.

//Now that you know the total number of  gravity points, you can calculate the OG of your beer.
//To calculate the OG, you need to determine volume of wort you will have at the end of the boil.
//Divide the total number of gravity points by the number of gallons of wort.
//For the example above, lets assume this is a 6 gallon batch, so the wort will have 48.2 gravity points per gallon.
//Take this number, add 1000 and divide by 1000, this value is your OG. The OG for the grain bill above in 6 gallons of wort is 1.048. To calculate the OG you could just tack on the first two digits of the number of gravity points per gallon (48.2) to the end of 1.0 to get 1.048.

//function tryConvert(temperature, convert) {
  //const input = parseFloat(temperature);
  //if (Number.isNaN(input)) {
    //return '';
  //}
  //const output = convert(input);
  //const rounded = Math.round(output * 1000) / 1000;
  //return rounded.toString();
//}

//<!-- Heroicon name: mail-open -->
//<!-- Heroicon name: cursor-click -->

//http://www.brewunited.com/abv_calculator.php#:~:text=The%20basic%20formula%20used%20by,your%20ABV%20would%20be%205.25%25.
//simple: ABV = (OG - FG) * 131.25.
//advanced (more accurate): ABW = (OE - RE) / (2.0665 - (.010665 * OE) )

//https://www.brewersfriend.com/2011/06/16/alcohol-by-volume-calculator-updated/
//ABV =(76.08 * (og-fg) / (1.775-og)) * (fg / 0.794)
//
//https://byo.com/article/attenuation-advanced-brewing/#:~:text=where%20AA%20is%20apparent%20attenuation,10)%2F50%20%3D%2080%25.
//Thus to convert the refractometer scale from °Brix to °Plato, you need to divide the reading by 1.04. To convert °Plato to specific gravity, the approximation equation is:
//SG = 260/(260 – P)
//AA = (OG – FG)/OG
//
//https://straighttothepint.com/beer-calories-calculator/
//(ABV% * 2.5 ) * oz
//http://brewcalcs.com/
// https://cotubrewing.com/homebrewing/abv-formula/
//ABV = (1.05/0.79) X ((OG – TG) / TG) X 100
//
//Brix -> SG Equation:
//SG = (Brix / (258.6-((Brix / 258.2)*227.1))) + 1
//(Source: Brew Your Own Magazine)

//SG -> Brix Equation:
//Brix = (((182.4601 * SG -775.6821) * SG +1262.7794) * SG -669.5622)
//(Source: http://en.wikipedia.org/wiki/Brix)

const Calculators = {
  round: (num, places) => {
    return +(Math.round(num + `e+${places}`)  + `e-${places}`);
  },
  //roundString: (numStr, places) => {
    //return Calculators.round(
      //Calculators.strToFloat(numStr),
      //places
    //);
  //},
  strToFloat: (strValue) => {
    const floatValue = parseFloat(strValue);

    if (Number.isNaN(floatValue)) {
      return 0.0;
    } else {
      return floatValue;
    }
  },
  totalPointsFromIngredients: (ingredients) => {
    return ingredients.reduce((acc, ingredient) => {
      const fermentable = Fermentable.find(ingredient.fermentable_id);
      const maltPoints = ingredient.pounds * fermentable.ppg;

      return acc + maltPoints;
    }, 0);
  },
  originalGravity: (totalPotentialPoints, gallons, efficiency) => {
    const realisticPoints = (totalPotentialPoints / gallons) * efficiency;
    const og = (realisticPoints + 1000) / 1000;
    return Calculators.round(og, 3);
  }
}

export default Calculators;
