import { lazy } from 'react';
import OriginalGravityCalculator from './routes/OriginalGravityCalculator';

export const ROUTES = {
  ROOT: {
    path: "/",
    title: "Home",
    component: lazy(() => import('./routes/Home'))
  },
  RECIPES: {
    EDIT: {
      path: "/recipes/edit",
      title: "Recipe Editor",
      component: lazy(() => import('./routes/RecipeEdit'))
    }
  },
  CALCULATORS: {
    ORIGINAL_GRAVITY: {
      path: "/calculators/original-gravity",
      title: "Original Gravity Calculator",
      component: OriginalGravityCalculator
    }
  },
  ABV_CALCULATOR: {
    path: "/calculators/abv",
    title: "ABV Calculator",
    component: lazy(() => import('./routes/AbvCalculator'))
  },
  BRIX_CONVERTER: {
    path: "/calculators/brix-to-specific-gravity",
    title: "Brix Conversion Calculator",
    component: lazy(() => import('./routes/BrixConverter'))
  }
}
