import React, { lazy } from 'react';
import { NavLink } from "react-router-dom";
import { ROUTES } from './app_routes';
//import Dropdown from './components/Dropdown';

const NAV_LINKS = [
  ROUTES.ABV_CALCULATOR,
  ROUTES.BRIX_CONVERTER,
  ROUTES.CALCULATORS.ORIGINAL_GRAVITY,
]

class AppHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuEnabled: false
    }
  }

  toggleMenu = () => {
    this.setState({
      menuEnabled: !this.state.menuEnabled
    });
  }

  closeMenu = () => {
    this.setState({
      menuEnabled: false
    });
  }

  render() {

    // active:
    //<a href="/" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">
    const navMenuLinks = NAV_LINKS.map((link, index) =>
      <NavLink
        to={link.path}
        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
        activeClassName="bg-gray-900"
        exact={!!link.exact}
        key={index}
      >
        {link.title}
      </NavLink>
    );

    // active:
    //<a href="/" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
    const mobileNavMenuLinks = NAV_LINKS.map((link, index) =>
      <NavLink
        to={link.path}
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
        activeClassName="bg-gray-900"
        onClick={this.closeMenu}
        exact={!!link.exact}
        key={index}
      >
        {link.title}
      </NavLink>
    );

    return (
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
                onClick={this.toggleMenu}
              >
                <span className="sr-only">Open main menu</span>

                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>

                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <NavLink
                to="/"
                className="flex-shrink-0 flex items-center space-x-2"
                onClick={this.closeMenu}
              >
                <div className="flex items-center">
                  <svg className="block h-8 w-auto text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                  </svg>
                </div>

                <div className="flex items-center">
                  <h2 className="text-xl font-bold leading-7 text-white sm:text-xl sm:truncate">
                    BrewDaddy
                  </h2>
                </div>
              </NavLink>

              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {navMenuLinks}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${this.state.menuEnabled ? "" : "hidden"} sm:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {mobileNavMenuLinks}
          </div>
        </div>
      </nav>
    );
  }
}

export default AppHeader;
