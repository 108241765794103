function AppFooter() {
  return (
    <footer>
      <div className="max-w-7xl mx-auto py-5 px-4 overflow-hidden sm:px-6 lg:px-8">
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2020 BrewDaddy. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default AppFooter;
