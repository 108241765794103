import FERMENTABLES, { FERMENTABLE_MAP } from '../fixtures/Fermentables';

const Fermentable = {
  all: () => {
    return FERMENTABLES;
  },
  find: (id) => {
    return FERMENTABLE_MAP[id];
  }
}

export default Fermentable;
