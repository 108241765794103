import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import { ROUTES } from './app_routes';

const App = () => (
  <div className="App">
    <Router>
      <AppHeader />

      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={ROUTES.ABV_CALCULATOR.path} component={ROUTES.ABV_CALCULATOR.component}/>
          <Route exact path={ROUTES.BRIX_CONVERTER.path} component={ROUTES.BRIX_CONVERTER.component}/>
          <Route exact path={ROUTES.RECIPES.EDIT.path} component={ROUTES.RECIPES.EDIT.component}/>
          <Route exact path={ROUTES.CALCULATORS.ORIGINAL_GRAVITY.path} component={ROUTES.CALCULATORS.ORIGINAL_GRAVITY.component}/>
          <Route exact path={ROUTES.ROOT.path} component={ROUTES.ROOT.component}/>
        </Switch>
      </Suspense>
    </Router>

    <AppFooter />
  </div>
);

export default App;
